import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { HandCombosContext, PlayerSelectedContext } from "../context/store";
import {
  ActionTypes,
  FONT_FAMILY,
  PRESET_HANDS,
  PLAYER_NAMES,
  ZINDEXES
} from "../constants";
import CopyIcon from "./icons/Copy";
import InstructionsIcon from "./icons/Instructions";
import HomeIcon from "./icons/Home";
import TwitterIcon from "./icons/Twitter";

function TwitterLink(props) {
  return (
    <div style={{ gridRowStart: "twitter" }}>
      <a href="http://twitter.com/handcombos">
        <TwitterIcon />
      </a>
    </div>
  );
}
function PresetRangesFlyout(props) {
  const { disabled } = props;
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [playerSelected, handleButtonDispatch] = useContext(
    PlayerSelectedContext
  );

  function getPresetOptions() {
    const options = Object.keys(PRESET_HANDS).map(preset => {
      return <option key={preset}>{preset}</option>;
    });
    options.unshift(
      <option
        value="Choose a preset"
        defaultValue="Choose a preset"
        key="defaultPreset"
      >
        Choose a preset
      </option>
    );
    return options;
  }

  function onSelection(e) {
    if (Object.keys(PRESET_HANDS).includes(e.target.value)) {
      handleSelectedDispatch({
        type: ActionTypes.REPLACE_HAND_COMBOS,
        player: playerSelected,
        newCombos: PRESET_HANDS[e.target.value]
      });
    }
  }

  const flyoutStyle = {
    gridRowStart: "preset-flyout-start",
    gridRowEnd: "copy",
    color: "black",
    marginLeft: "1rem",
    marginRight: "1rem",
    display: "block",
    background: "#e7e7e7",
    border: "1px solid rgba(0, 0, 0, 0.04)",
    borderRadius: "3px",
    fontFamily: FONT_FAMILY,
    fontSize: ".75rem",
    zIndex: ZINDEXES.flyouts
  };
  const selectStyle = {
    width: "100%",
    cursor: "pointer",
    lineHeight: "initial",
    padding: "0.5rem",
    paddingRight: "2rem",
    minWidth: "3rem",
    cursor: "pointer"
  };

  return (
    <>
      {disabled ? null : (
        <div style={flyoutStyle}>
          Hand range presets
          <select
            title="Select from the list of situations"
            onChange={onSelection}
            style={selectStyle}
          >
            {getPresetOptions()}}
          </select>
        </div>
      )}
    </>
  );
}

export function SelectPresetAction() {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [disableFlyout, setDisableFlyout] = useState(true);

  function handleClick(e) {
    setDisableFlyout(!disableFlyout);
  }
  const presetStyle = {
    gridRowStart: "preset"
  };
  return (
    <>
      <div onClick={handleClick} style={presetStyle}>
        <span style={{ cursor: "pointer" }}>Presets</span>
      </div>
      <PresetRangesFlyout disabled={disableFlyout} />
    </>
  );
}

function CopyInputRangesFlyout(props) {
  const { disabled } = props;
  const [copyPlayers, setCopyPlayers] = useState([null, null]);
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );

  function getPlayerOptions() {
    const options = PLAYER_NAMES.map(player => {
      return <option key={player}>{player}</option>;
    });
    options.unshift(
      <option value="Choose player" defaultValue="Choose player" key="default">
        Choose player
      </option>
    );
    return options;
  }

  function onSelection(e) {
    if (e.target.title === "from") {
      setCopyPlayers([e.target.value, copyPlayers[1]]);
    }
    if (e.target.title === "to") {
      setCopyPlayers([copyPlayers[0], e.target.value]);
    }
  }
  function onFlyoutSubmit() {
    if (!copyPlayers[0] || !copyPlayers[1]) {
      return;
    }
    if (
      copyPlayers[0] === "Choose player" ||
      copyPlayers[1] === "Choose player"
    ) {
      return;
    }
    if (copyPlayers[0] === copyPlayers[1]) {
      return;
    }
    const from = copyPlayers[0];
    const to = copyPlayers[1];
    const combos = { ...selectedCombos[from] };
    delete combos["selected"];
    handleSelectedDispatch({
      type: ActionTypes.UPDATE_MANY_COMBOS,
      player: to,
      newCombos: combos
    });
  }

  const flyoutStyle = {
    position: "absolute",
    width: "220px",
    color: "black",
    marginLeft: "6rem",
    display: "block",
    background: "#e7e7e7",
    border: "1px solid rgba(0, 0, 0, 0.04)",
    borderRadius: "3px",
    //boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14)",
    fontFamily: FONT_FAMILY,
    fontSize: ".75rem",
    zIndex: ZINDEXES.flyouts
  };
  const selectStyle = {
    width: "100%",
    //appearance: "none",
    //background: "#fff",
    //border: "none",
    cursor: "pointer",
    lineHeight: "initial",
    padding: "0.5rem",
    paddingRight: "2rem",
    minWidth: "3rem"
  };
  const buttonStyle = {
    color: "white",
    backgroundColor: "#29945c",
    borderRaidus: "3px"
  };
  return (
    <>
      {disabled ? null : (
        <div style={flyoutStyle}>
          From:
          <select
            title="from"
            onChange={onSelection}
            style={selectStyle}
            selected="Please select a player"
          >
            {getPlayerOptions()}}
          </select>
          To:
          <select
            title="to"
            onChange={onSelection}
            style={selectStyle}
            selected="Please select a player"
          >
            {getPlayerOptions()}}
          </select>
          <button style={buttonStyle} onClick={onFlyoutSubmit}>
            click to copy
          </button>
        </div>
      )}
    </>
  );
}

export function CopyRangeAction() {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [disableFlyout, setDisableFlyout] = useState(true);

  function handleClick(e) {
    setDisableFlyout(!disableFlyout);
  }
  const copyStyle = { gridRowStart: "copy", cursor: "pointer" };
  return (
    <>
      <div style={copyStyle}>
        <div onClick={handleClick} title="Copy player ranges">
          <CopyIcon />
        </div>
        <CopyInputRangesFlyout disabled={disableFlyout} />
      </div>
    </>
  );
}

function InstructionLink() {
  const instructionsStyle = {
    gridRowStart: "instructions"
  };
  return (
    <div style={instructionsStyle} title="Instructions">
      <Link to="/guide">
        <InstructionsIcon />
      </Link>
    </div>
  );
}

function HomeLink() {
  const instructionsStyle = {
    gridRowStart: "home"
  };
  return (
    <div style={instructionsStyle} title="Calculator Home">
      <Link to="/">
        <HomeIcon />
      </Link>
    </div>
  );
}

export function LeftSideBar(props) {
  // TODO: When presets are implemented move copy icon below back down
  const style = {
    background: "#273238",
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr)",
    gridTemplateRows:
      "[logo] 76px [logo-gap] minmax(0, 1fr) [home] minmax(0, 1fr) [instructions] minmax(0, 1fr) [copy] minmax(0, 1fr) [twitter] minmax(0, 1fr) [copy-flyout-start] minmax(0, 1fr) repeat(1, minmax(0, 1fr)) [copy-flyout-end] minmax(0,1fr) [preset] minmax(0, 1fr) [preset-flyout-start]  minmax(0, 1fr)repeat(4, minmax(0, 1fr))",

    color: "white",
    textDecoration: "none",
    fontFamily:
      "Verdana, Geneva, DejaVu, Sans, Bitstream, Vera, Sans, Liberation, Sans, Verdana, Ref, sans-serif",
    fontSize: "1em",
    fontWeight: "600",
    alignItems: "center",
    textAlign: "center"
  };
  return (
    <div style={style}>
      <HomeLink />
      <InstructionLink />
      <CopyRangeAction />
      <TwitterLink />
    </div>
  );
}
