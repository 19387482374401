export const SOCIAL_AUTH_GOOGLE_OAUTH2_KEY =
  "393970666992-0su0plnh4ords6mcjjod9dv9fn36cbu5.apps.googleusercontent.com";
export const SOCIAL_AUTH_GOOGLE_OAUTH2_SECRET = "TjJ2OcmbMQsOlufAXP1muoug";

export const AUTH_TOKEN_NAME = "token";

export const FONT_FAMILY =
  "Verdana, Geneva, DejaVu, Sans, Bitstream, Vera, Sans, Liberation, Sans, Verdana, Ref, sans-serif";
export const CARD_LIST = [
  "A",
  "K",
  "Q",
  "J",
  "T",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2"
];
export const SUIT_LIST = ["c", "s", "h", "d"];
export const ALL_SUIT_COMBOS = [
  "cs",
  "ch",
  "cd",
  "sc",
  "sh",
  "sd",
  "hc",
  "hs",
  "hd",
  "dc",
  "ds",
  "dh",
  "cc",
  "ss",
  "hh",
  "dd"
];
export const VALID_HOLE_CARDS = new Set([
  "As",
  "Ac",
  "Ah",
  "Ad",
  "Ks",
  "Kc",
  "Kh",
  "Kd",
  "Qs",
  "Qc",
  "Qh",
  "Qd",
  "Js",
  "Jc",
  "Jh",
  "Jd",
  "Ts",
  "Tc",
  "Th",
  "Td",
  "9s",
  "9c",
  "9h",
  "9d",
  "8s",
  "8c",
  "8h",
  "8d",
  "7s",
  "7c",
  "7h",
  "7d",
  "6s",
  "6c",
  "6h",
  "6d",
  "5s",
  "5c",
  "5h",
  "5d",
  "4s",
  "4c",
  "4h",
  "4d",
  "3s",
  "3c",
  "3h",
  "3d",
  "2s",
  "2c",
  "2h",
  "2d"
]);

export const POCKET_PAIRS = [
  "AA",
  "KK",
  "QQ",
  "JJ",
  "TT",
  "99",
  "88",
  "77",
  "66",
  "55",
  "44",
  "33",
  "22"
];

export const PRESET_HANDS = {
  "UTG raise first in": {
    AA: ["AcAh", "AcAd", "AcAs", "AhAd", "AhAs", "AdAs"],
    KK: ["KcKh", "KcKd", "KcKs", "KhKd", "KhKs", "KdKs"],
    QQ: ["QcQh", "QcQd", "QcQs", "QhQd", "QhQs", "QdQs"],
    JJ: ["JcJh", "JcJd", "JcJs", "JhJd", "JhJs", "JdJs"],
    TT: ["TcTh", "TcTd", "TcTs", "ThTd", "ThTs", "TdTs"],
    AKs: ["AsKs", "AhKh", "AdKd", "AcKc"],
    99: ["9c9h", "9c9d", "9c9s", "9h9d", "9h9s", "9d9s"],
    AKo: [
      "AcKs",
      "AcKh",
      "AcKd",
      "AsKc",
      "AsKh",
      "AsKd",
      "AhKc",
      "AhKs",
      "AhKd",
      "AdKc",
      "AdKs",
      "AdKh"
    ],
    AQs: ["AsQs", "AhQh", "AdQd", "AcQc"],
    AQo: [
      "AcQs",
      "AcQh",
      "AcQd",
      "AsQc",
      "AsQh",
      "AsQd",
      "AhQc",
      "AhQs",
      "AhQd",
      "AdQc",
      "AdQs",
      "AdQh"
    ],
    AJs: ["AsJs", "AhJh", "AdJd", "AcJc"],
    KQs: ["KsQs", "KhQh", "KdQd", "KcQc"],
    88: ["8c8h", "8c8d", "8c8s", "8h8d", "8h8s", "8d8s"],
    AJo: [
      "AcJs",
      "AcJh",
      "AcJd",
      "AsJc",
      "AsJh",
      "AsJd",
      "AhJc",
      "AhJs",
      "AhJd",
      "AdJc",
      "AdJs",
      "AdJh"
    ],
    ATs: ["AsTs", "AhTh", "AdTd", "AcTc"],
    KQo: [
      "KcQs",
      "KcQh",
      "KcQd",
      "KsQc",
      "KsQh",
      "KsQd",
      "KhQc",
      "KhQs",
      "KhQd",
      "KdQc",
      "KdQs",
      "KdQh"
    ]
  }
};
export const ORDERED_STARTING_HAND_COMBOS = [
  { AA: ["AcAh", "AcAd", "AcAs", "AhAd", "AhAs", "AdAs"] },
  { KK: ["KcKh", "KcKd", "KcKs", "KhKd", "KhKs", "KdKs"] },
  { QQ: ["QcQh", "QcQd", "QcQs", "QhQd", "QhQs", "QdQs"] },
  { JJ: ["JcJh", "JcJd", "JcJs", "JhJd", "JhJs", "JdJs"] },
  { TT: ["TcTh", "TcTd", "TcTs", "ThTd", "ThTs", "TdTs"] },
  { AKs: ["AsKs", "AhKh", "AdKd", "AcKc"] },
  { 99: ["9c9h", "9c9d", "9c9s", "9h9d", "9h9s", "9d9s"] },
  {
    AKo: [
      "AcKs",
      "AcKh",
      "AcKd",
      "AsKc",
      "AsKh",
      "AsKd",
      "AhKc",
      "AhKs",
      "AhKd",
      "AdKc",
      "AdKs",
      "AdKh"
    ]
  },
  { AQs: ["AsQs", "AhQh", "AdQd", "AcQc"] },
  {
    AQo: [
      "AcQs",
      "AcQh",
      "AcQd",
      "AsQc",
      "AsQh",
      "AsQd",
      "AhQc",
      "AhQs",
      "AhQd",
      "AdQc",
      "AdQs",
      "AdQh"
    ]
  },
  { AJs: ["AsJs", "AhJh", "AdJd", "AcJc"] },
  { KQs: ["KsQs", "KhQh", "KdQd", "KcQc"] },
  { 88: ["8c8h", "8c8d", "8c8s", "8h8d", "8h8s", "8d8s"] },
  {
    AJo: [
      "AcJs",
      "AcJh",
      "AcJd",
      "AsJc",
      "AsJh",
      "AsJd",
      "AhJc",
      "AhJs",
      "AhJd",
      "AdJc",
      "AdJs",
      "AdJh"
    ]
  },
  { ATs: ["AsTs", "AhTh", "AdTd", "AcTc"] },
  {
    KQo: [
      "KcQs",
      "KcQh",
      "KcQd",
      "KsQc",
      "KsQh",
      "KsQd",
      "KhQc",
      "KhQs",
      "KhQd",
      "KdQc",
      "KdQs",
      "KdQh"
    ]
  },

  {
    ATo: [
      "AcTs",
      "AcTh",
      "AcTd",
      "AsTc",
      "AsTh",
      "AsTd",
      "AhTc",
      "AhTs",
      "AhTd",
      "AdTc",
      "AdTs",
      "AdTh"
    ]
  },
  { A9s: ["As9s", "Ah9h", "Ad9d", "Ac9c"] },
  { 77: ["7c7h", "7c7d", "7c7s", "7h7d", "7h7s", "7d7s"] },
  { A8s: ["As8s", "Ah8h", "Ad8d", "Ac8c"] },
  { KJs: ["KsJs", "KhJh", "KdJd", "KcJc"] },

  { A7s: ["As7s", "Ah7h", "Ad7d", "Ac7c"] },
  { A6s: ["As6s", "Ah6h", "Ad6d", "Ac6c"] },
  { A5s: ["As5s", "Ah5h", "Ad5d", "Ac5c"] },
  { A4s: ["As4s", "Ah4h", "Ad4d", "Ac4c"] },
  { A3s: ["As3s", "Ah3h", "Ad3d", "Ac3c"] },
  { A2s: ["As2s", "Ah2h", "Ad2d", "Ac2c"] },
  {
    KJo: [
      "KcJs",
      "KcJh",
      "KcJd",
      "KsJc",
      "KsJh",
      "KsJd",
      "KhJc",
      "KhJs",
      "KhJd",
      "KdJc",
      "KdJs",
      "KdJh"
    ]
  },
  { KTs: ["KsTs", "KhTh", "KdTd", "KcTc"] },
  { K9s: ["Ks9s", "Kh9h", "Kd9d", "Kc9c"] },
  { QJs: ["QsJs", "QhJh", "QdJd", "QcJc"] },
  {
    KTo: [
      "KcTs",
      "KcTh",
      "KcTd",
      "KsTc",
      "KsTh",
      "KsTd",
      "KhTc",
      "KhTs",
      "KhTd",
      "KdTc",
      "KdTs",
      "KdTh"
    ]
  },
  {
    QJo: [
      "QcJs",
      "QcJh",
      "QcJd",
      "QsJc",
      "QsJh",
      "QsJd",
      "QhJc",
      "QhJs",
      "QhJd",
      "QdJc",
      "QdJs",
      "QdJh"
    ]
  },
  { QTs: ["QsTs", "QhTh", "QdTd", "QcTc"] },
  { JTs: ["JsTs", "JhTh", "JdTd", "JcTc"] },
  {
    A9o: [
      "Ac9s",
      "Ac9h",
      "Ac9d",
      "As9c",
      "As9h",
      "As9d",
      "Ah9c",
      "Ah9s",
      "Ah9d",
      "Ad9c",
      "Ad9s",
      "Ad9h"
    ]
  },

  { Q9s: ["Qs9s", "Qh9h", "Qd9d", "Qc9c"] },
  { J9s: ["Js9s", "Jh9h", "Jd9d", "Jc9c"] },
  { T9s: ["Ts9s", "Th9h", "Td9d", "Tc9c"] },

  {
    QTo: [
      "QcTs",
      "QcTh",
      "QcTd",
      "QsTc",
      "QsTh",
      "QsTd",
      "QhTc",
      "QhTs",
      "QhTd",
      "QdTc",
      "QdTs",
      "QdTh"
    ]
  },
  { K8s: ["Ks8s", "Kh8h", "Kd8d", "Kc8c"] },
  {
    JTo: [
      "JcTs",
      "JcTh",
      "JcTd",
      "JsTc",
      "JsTh",
      "JsTd",
      "JhTc",
      "JhTs",
      "JhTd",
      "JdTc",
      "JdTs",
      "JdTh"
    ]
  },

  { "98s": ["9s8s", "9h8h", "9d8d", "9c8c"] },
  { 66: ["6c6h", "6c6d", "6c6s", "6h6d", "6h6s", "6d6s"] },
  { "87s": ["8s7s", "8h7h", "8d7d", "8c7c"] },

  { 55: ["5c5h", "5c5d", "5c5s", "5h5d", "5h5s", "5d5s"] },
  {
    A8o: [
      "Ac8s",
      "Ac8h",
      "Ac8d",
      "As8c",
      "As8h",
      "As8d",
      "Ah8c",
      "Ah8s",
      "Ah8d",
      "Ad8c",
      "Ad8s",
      "Ad8h"
    ]
  },
  {
    K9o: [
      "Kc9s",
      "Kc9h",
      "Kc9d",
      "Ks9c",
      "Ks9h",
      "Ks9d",
      "Kh9c",
      "Kh9s",
      "Kh9d",
      "Kd9c",
      "Kd9s",
      "Kd9h"
    ]
  },
  {
    A7o: [
      "Ac7s",
      "Ac7h",
      "Ac7d",
      "As7c",
      "As7h",
      "As7d",
      "Ah7c",
      "Ah7s",
      "Ah7d",
      "Ad7c",
      "Ad7s",
      "Ad7h"
    ]
  },

  { K7s: ["Ks7s", "Kh7h", "Kd7d", "Kc7c"] },
  { K6s: ["Ks6s", "Kh6h", "Kd6d", "Kc6c"] },
  {
    Q9o: [
      "Qc9s",
      "Qc9h",
      "Qc9d",
      "Qs9c",
      "Qs9h",
      "Qs9d",
      "Qh9c",
      "Qh9s",
      "Qh9d",
      "Qd9c",
      "Qd9s",
      "Qd9h"
    ]
  },

  { Q8s: ["Qs8s", "Qh8h", "Qd8d", "Qc8c"] },
  {
    J9o: [
      "Jc9s",
      "Jc9h",
      "Jc9d",
      "Js9c",
      "Js9h",
      "Js9d",
      "Jh9c",
      "Jh9s",
      "Jh9d",
      "Jd9c",
      "Jd9s",
      "Jd9h"
    ]
  },
  { J8s: ["Js8s", "Jh8h", "Jd8d", "Jc8c"] },
  { "76s": ["7s6s", "7h6h", "7d6d", "7c6c"] },
  { Q7s: ["Qs7s", "Qh7h", "Qd7d", "Qc7c"] },
  {
    T9o: [
      "Tc9s",
      "Tc9h",
      "Tc9d",
      "Ts9c",
      "Ts9h",
      "Ts9d",
      "Th9c",
      "Th9s",
      "Th9d",
      "Td9c",
      "Td9s",
      "Td9h"
    ]
  },
  {
    A6o: [
      "Ac6s",
      "Ac6h",
      "Ac6d",
      "As6c",
      "As6h",
      "As6d",
      "Ah6c",
      "Ah6s",
      "Ah6d",
      "Ad6c",
      "Ad6s",
      "Ad6h"
    ]
  },
  {
    A5o: [
      "Ac5s",
      "Ac5h",
      "Ac5d",
      "As5c",
      "As5h",
      "As5d",
      "Ah5c",
      "Ah5s",
      "Ah5d",
      "Ad5c",
      "Ad5s",
      "Ad5h"
    ]
  },
  {
    A4o: [
      "Ac4s",
      "Ac4h",
      "Ac4d",
      "As4c",
      "As4h",
      "As4d",
      "Ah4c",
      "Ah4s",
      "Ah4d",
      "Ad4c",
      "Ad4s",
      "Ad4h"
    ]
  },
  {
    A3o: [
      "Ac3s",
      "Ac3h",
      "Ac3d",
      "As3c",
      "As3h",
      "As3d",
      "Ah3c",
      "Ah3s",
      "Ah3d",
      "Ad3c",
      "Ad3s",
      "Ad3h"
    ]
  },
  {
    A2o: [
      "Ac2s",
      "Ac2h",
      "Ac2d",
      "As2c",
      "As2h",
      "As2d",
      "Ah2c",
      "Ah2s",
      "Ah2d",
      "Ad2c",
      "Ad2s",
      "Ad2h"
    ]
  },
  { T8s: ["Ts8s", "Th8h", "Td8d", "Tc8c"] },
  { 44: ["4c4h", "4c4d", "4c4s", "4h4d", "4h4s", "4d4s"] },
  { 33: ["3c3h", "3c3d", "3c3s", "3h3d", "3h3s", "3d3s"] },
  { 22: ["2c2h", "2c2d", "2c2s", "2h2d", "2h2s", "2d2s"] },
  { K5s: ["Ks5s", "Kh5h", "Kd5d", "Kc5c"] },

  { K4s: ["Ks4s", "Kh4h", "Kd4d", "Kc4c"] },
  {
    K8o: [
      "Kc8s",
      "Kc8h",
      "Kc8d",
      "Ks8c",
      "Ks8h",
      "Ks8d",
      "Kh8c",
      "Kh8s",
      "Kh8d",
      "Kd8c",
      "Kd8s",
      "Kd8h"
    ]
  },
  { "65s": ["6s5s", "6h5h", "6d5d", "6c5c"] },
  { K3s: ["Ks3s", "Kh3h", "Kd3d", "Kc3c"] },
  { K2s: ["Ks2s", "Kh2h", "Kd2d", "Kc2c"] },
  { Q6s: ["Qs6s", "Qh6h", "Qd6d", "Qc6c"] },
  { J7s: ["Js7s", "Jh7h", "Jd7d", "Jc7c"] },
  { T7s: ["Ts7s", "Th7h", "Td7d", "Tc7c"] },
  { Q5s: ["Qs5s", "Qh5h", "Qd5d", "Qc5c"] },

  {
    K7o: [
      "Kc7s",
      "Kc7h",
      "Kc7d",
      "Ks7c",
      "Ks7h",
      "Ks7d",
      "Kh7c",
      "Kh7s",
      "Kh7d",
      "Kd7c",
      "Kd7s",
      "Kd7h"
    ]
  },

  { "97s": ["9s7s", "9h7h", "9d7d", "9c7c"] },
  {
    Q8o: [
      "Qc8s",
      "Qc8h",
      "Qc8d",
      "Qs8c",
      "Qs8h",
      "Qs8d",
      "Qh8c",
      "Qh8s",
      "Qh8d",
      "Qd8c",
      "Qd8s",
      "Qd8h"
    ]
  },

  {
    J8o: [
      "Jc8s",
      "Jc8h",
      "Jc8d",
      "Js8c",
      "Js8h",
      "Js8d",
      "Jh8c",
      "Jh8s",
      "Jh8d",
      "Jd8c",
      "Jd8s",
      "Jd8h"
    ]
  },
  {
    T8o: [
      "Tc8s",
      "Tc8h",
      "Tc8d",
      "Ts8c",
      "Ts8h",
      "Ts8d",
      "Th8c",
      "Th8s",
      "Th8d",
      "Td8c",
      "Td8s",
      "Td8h"
    ]
  },
  {
    "98o": [
      "9c8s",
      "9c8h",
      "9c8d",
      "9s8c",
      "9s8h",
      "9s8d",
      "9h8c",
      "9h8s",
      "9h8d",
      "9d8c",
      "9d8s",
      "9d8h"
    ]
  },
  { "54s": ["5s4s", "5h4h", "5d4d", "5c4c"] },

  { "96s": ["9s6s", "9h6h", "9d6d", "9c6c"] },
  { "86s": ["8s6s", "8h6h", "8d6d", "8c6c"] },
  { T6s: ["Ts6s", "Th6h", "Td6d", "Tc6c"] },
  { "95s": ["9s5s", "9h5h", "9d5d", "9c5c"] },
  { "85s": ["8s5s", "8h5h", "8d5d", "8c5c"] },
  { "75s": ["7s5s", "7h5h", "7d5d", "7c5c"] },
  { "74s": ["7s4s", "7h4h", "7d4d", "7c4c"] },
  { "64s": ["6s4s", "6h4h", "6d4d", "6c4c"] },
  { Q4s: ["Qs4s", "Qh4h", "Qd4d", "Qc4c"] },
  { Q3s: ["Qs3s", "Qh3h", "Qd3d", "Qc3c"] },
  { Q2s: ["Qs2s", "Qh2h", "Qd2d", "Qc2c"] },
  {
    K6o: [
      "Kc6s",
      "Kc6h",
      "Kc6d",
      "Ks6c",
      "Ks6h",
      "Ks6d",
      "Kh6c",
      "Kh6s",
      "Kh6d",
      "Kd6c",
      "Kd6s",
      "Kd6h"
    ]
  },
  {
    K5o: [
      "Kc5s",
      "Kc5h",
      "Kc5d",
      "Ks5c",
      "Ks5h",
      "Ks5d",
      "Kh5c",
      "Kh5s",
      "Kh5d",
      "Kd5c",
      "Kd5s",
      "Kd5h"
    ]
  },

  {
    K4o: [
      "Kc4s",
      "Kc4h",
      "Kc4d",
      "Ks4c",
      "Ks4h",
      "Ks4d",
      "Kh4c",
      "Kh4s",
      "Kh4d",
      "Kd4c",
      "Kd4s",
      "Kd4h"
    ]
  },
  {
    K3o: [
      "Kc3s",
      "Kc3h",
      "Kc3d",
      "Ks3c",
      "Ks3h",
      "Ks3d",
      "Kh3c",
      "Kh3s",
      "Kh3d",
      "Kd3c",
      "Kd3s",
      "Kd3h"
    ]
  },
  {
    K2o: [
      "Kc2s",
      "Kc2h",
      "Kc2d",
      "Ks2c",
      "Ks2h",
      "Ks2d",
      "Kh2c",
      "Kh2s",
      "Kh2d",
      "Kd2c",
      "Kd2s",
      "Kd2h"
    ]
  },
  {
    Q7o: [
      "Qc7s",
      "Qc7h",
      "Qc7d",
      "Qs7c",
      "Qs7h",
      "Qs7d",
      "Qh7c",
      "Qh7s",
      "Qh7d",
      "Qd7c",
      "Qd7s",
      "Qd7h"
    ]
  },
  {
    J7o: [
      "Jc7s",
      "Jc7h",
      "Jc7d",
      "Js7c",
      "Js7h",
      "Js7d",
      "Jh7c",
      "Jh7s",
      "Jh7d",
      "Jd7c",
      "Jd7s",
      "Jd7h"
    ]
  },
  {
    Q6o: [
      "Qc6s",
      "Qc6h",
      "Qc6d",
      "Qs6c",
      "Qs6h",
      "Qs6d",
      "Qh6c",
      "Qh6s",
      "Qh6d",
      "Qd6c",
      "Qd6s",
      "Qd6h"
    ]
  },
  { J6s: ["Js6s", "Jh6h", "Jd6d", "Jc6c"] },
  {
    T7o: [
      "Tc7s",
      "Tc7h",
      "Tc7d",
      "Ts7c",
      "Ts7h",
      "Ts7d",
      "Th7c",
      "Th7s",
      "Th7d",
      "Td7c",
      "Td7s",
      "Td7h"
    ]
  },
  {
    "97o": [
      "9c7s",
      "9c7h",
      "9c7d",
      "9s7c",
      "9s7h",
      "9s7d",
      "9h7c",
      "9h7s",
      "9h7d",
      "9d7c",
      "9d7s",
      "9d7h"
    ]
  },
  {
    "87o": [
      "8c7s",
      "8c7h",
      "8c7d",
      "8s7c",
      "8s7h",
      "8s7d",
      "8h7c",
      "8h7s",
      "8h7d",
      "8d7c",
      "8d7s",
      "8d7h"
    ]
  },
  { J5s: ["Js5s", "Jh5h", "Jd5d", "Jc5c"] },
  { J4s: ["Js4s", "Jh4h", "Jd4d", "Jc4c"] },
  { J3s: ["Js3s", "Jh3h", "Jd3d", "Jc3c"] },
  { "63s": ["6s3s", "6h3h", "6d3d", "6c3c"] },
  { "53s": ["5s3s", "5h3h", "5d3d", "5c3c"] },
  { "43s": ["4s3s", "4h3h", "4d3d", "4c3c"] },
  { J2s: ["Js2s", "Jh2h", "Jd2d", "Jc2c"] },
  {
    Q5o: [
      "Qc5s",
      "Qc5h",
      "Qc5d",
      "Qs5c",
      "Qs5h",
      "Qs5d",
      "Qh5c",
      "Qh5s",
      "Qh5d",
      "Qd5c",
      "Qd5s",
      "Qd5h"
    ]
  },
  {
    "76o": [
      "7c6s",
      "7c6h",
      "7c6d",
      "7s6c",
      "7s6h",
      "7s6d",
      "7h6c",
      "7h6s",
      "7h6d",
      "7d6c",
      "7d6s",
      "7d6h"
    ]
  },
  {
    Q4o: [
      "Qc4s",
      "Qc4h",
      "Qc4d",
      "Qs4c",
      "Qs4h",
      "Qs4d",
      "Qh4c",
      "Qh4s",
      "Qh4d",
      "Qd4c",
      "Qd4s",
      "Qd4h"
    ]
  },
  { "84s": ["8s4s", "8h4h", "8d4d", "8c4c"] },
  { J2s: ["Js2s", "Jh2h", "Jd2d", "Jc2c"] },
  { T5s: ["Ts5s", "Th5h", "Td5d", "Tc5c"] },
  { T4s: ["Ts4s", "Th4h", "Td4d", "Tc4c"] },
  { T3s: ["Ts3s", "Th3h", "Td3d", "Tc3c"] },
  { T2s: ["Ts2s", "Th2h", "Td2d", "Tc2c"] },
  {
    T6o: [
      "Tc6s",
      "Tc6h",
      "Tc6d",
      "Ts6c",
      "Ts6h",
      "Ts6d",
      "Th6c",
      "Th6s",
      "Th6d",
      "Td6c",
      "Td6s",
      "Td6h"
    ]
  },
  {
    "96o": [
      "9c6s",
      "9c6h",
      "9c6d",
      "9s6c",
      "9s6h",
      "9s6d",
      "9h6c",
      "9h6s",
      "9h6d",
      "9d6c",
      "9d6s",
      "9d6h"
    ]
  },
  {
    "86o": [
      "8c6s",
      "8c6h",
      "8c6d",
      "8s6c",
      "8s6h",
      "8s6d",
      "8h6c",
      "8h6s",
      "8h6d",
      "8d6c",
      "8d6s",
      "8d6h"
    ]
  },
  {
    Q3o: [
      "Qc3s",
      "Qc3h",
      "Qc3d",
      "Qs3c",
      "Qs3h",
      "Qs3d",
      "Qh3c",
      "Qh3s",
      "Qh3d",
      "Qd3c",
      "Qd3s",
      "Qd3h"
    ]
  },
  {
    Q2o: [
      "Qc2s",
      "Qc2h",
      "Qc2d",
      "Qs2c",
      "Qs2h",
      "Qs2d",
      "Qh2c",
      "Qh2s",
      "Qh2d",
      "Qd2c",
      "Qd2s",
      "Qd2h"
    ]
  },
  {
    J6o: [
      "Jc6s",
      "Jc6h",
      "Jc6d",
      "Js6c",
      "Js6h",
      "Js6d",
      "Jh6c",
      "Jh6s",
      "Jh6d",
      "Jd6c",
      "Jd6s",
      "Jd6h"
    ]
  },
  {
    "65o": [
      "6c5s",
      "6c5h",
      "6c5d",
      "6s5c",
      "6s5h",
      "6s5d",
      "6h5c",
      "6h5s",
      "6h5d",
      "6d5c",
      "6d5s",
      "6d5h"
    ]
  },

  { "73s": ["7s3s", "7h3h", "7d3d", "7c3c"] },
  {
    J5o: [
      "Jc5s",
      "Jc5h",
      "Jc5d",
      "Js5c",
      "Js5h",
      "Js5d",
      "Jh5c",
      "Jh5s",
      "Jh5d",
      "Jd5c",
      "Jd5s",
      "Jd5h"
    ]
  },
  {
    J4o: [
      "Jc4s",
      "Jc4h",
      "Jc4d",
      "Js4c",
      "Js4h",
      "Js4d",
      "Jh4c",
      "Jh4s",
      "Jh4d",
      "Jd4c",
      "Jd4s",
      "Jd4h"
    ]
  },
  {
    J3o: [
      "Jc3s",
      "Jc3h",
      "Jc3d",
      "Js3c",
      "Js3h",
      "Js3d",
      "Jh3c",
      "Jh3s",
      "Jh3d",
      "Jd3c",
      "Jd3s",
      "Jd3h"
    ]
  },
  {
    J2o: [
      "Jc2s",
      "Jc2h",
      "Jc2d",
      "Js2c",
      "Js2h",
      "Js2d",
      "Jh2c",
      "Jh2s",
      "Jh2d",
      "Jd2c",
      "Jd2s",
      "Jd2h"
    ]
  },

  { "62s": ["6s2s", "6h2h", "6d2d", "6c2c"] },
  { "52s": ["5s2s", "5h2h", "5d2d", "5c2c"] },
  { "42s": ["4s2s", "4h2h", "4d2d", "4c2c"] },
  { "32s": ["3s2s", "3h2h", "3d2d", "3c2c"] },
  {
    T5o: [
      "Tc5s",
      "Tc5h",
      "Tc5d",
      "Ts5c",
      "Ts5h",
      "Ts5d",
      "Th5c",
      "Th5s",
      "Th5d",
      "Td5c",
      "Td5s",
      "Td5h"
    ]
  },
  {
    T4o: [
      "Tc4s",
      "Tc4h",
      "Tc4d",
      "Ts4c",
      "Ts4h",
      "Ts4d",
      "Th4c",
      "Th4s",
      "Th4d",
      "Td4c",
      "Td4s",
      "Td4h"
    ]
  },
  {
    T3o: [
      "Tc3s",
      "Tc3h",
      "Tc3d",
      "Ts3c",
      "Ts3h",
      "Ts3d",
      "Th3c",
      "Th3s",
      "Th3d",
      "Td3c",
      "Td3s",
      "Td3h"
    ]
  },
  {
    T2o: [
      "Tc2s",
      "Tc2h",
      "Tc2d",
      "Ts2c",
      "Ts2h",
      "Ts2d",
      "Th2c",
      "Th2s",
      "Th2d",
      "Td2c",
      "Td2s",
      "Td2h"
    ]
  },
  {
    "95o": [
      "9c5s",
      "9c5h",
      "9c5d",
      "9s5c",
      "9s5h",
      "9s5d",
      "9h5c",
      "9h5s",
      "9h5d",
      "9d5c",
      "9d5s",
      "9d5h"
    ]
  },
  { "94s": ["9s4s", "9h4h", "9d4d", "9c4c"] },
  {
    "94o": [
      "9c4s",
      "9c4h",
      "9c4d",
      "9s4c",
      "9s4h",
      "9s4d",
      "9h4c",
      "9h4s",
      "9h4d",
      "9d4c",
      "9d4s",
      "9d4h"
    ]
  },
  { "93s": ["9s3s", "9h3h", "9d3d", "9c3c"] },
  {
    "93o": [
      "9c3s",
      "9c3h",
      "9c3d",
      "9s3c",
      "9s3h",
      "9s3d",
      "9h3c",
      "9h3s",
      "9h3d",
      "9d3c",
      "9d3s",
      "9d3h"
    ]
  },
  { "92s": ["9s2s", "9h2h", "9d2d", "9c2c"] },
  {
    "92o": [
      "9c2s",
      "9c2h",
      "9c2d",
      "9s2c",
      "9s2h",
      "9s2d",
      "9h2c",
      "9h2s",
      "9h2d",
      "9d2c",
      "9d2s",
      "9d2h"
    ]
  },
  {
    "85o": [
      "8c5s",
      "8c5h",
      "8c5d",
      "8s5c",
      "8s5h",
      "8s5d",
      "8h5c",
      "8h5s",
      "8h5d",
      "8d5c",
      "8d5s",
      "8d5h"
    ]
  },
  {
    "84o": [
      "8c4s",
      "8c4h",
      "8c4d",
      "8s4c",
      "8s4h",
      "8s4d",
      "8h4c",
      "8h4s",
      "8h4d",
      "8d4c",
      "8d4s",
      "8d4h"
    ]
  },
  { "83s": ["8s3s", "8h3h", "8d3d", "8c3c"] },
  {
    "83o": [
      "8c3s",
      "8c3h",
      "8c3d",
      "8s3c",
      "8s3h",
      "8s3d",
      "8h3c",
      "8h3s",
      "8h3d",
      "8d3c",
      "8d3s",
      "8d3h"
    ]
  },
  { "82s": ["8s2s", "8h2h", "8d2d", "8c2c"] },
  {
    "82o": [
      "8c2s",
      "8c2h",
      "8c2d",
      "8s2c",
      "8s2h",
      "8s2d",
      "8h2c",
      "8h2s",
      "8h2d",
      "8d2c",
      "8d2s",
      "8d2h"
    ]
  },
  {
    "75o": [
      "7c5s",
      "7c5h",
      "7c5d",
      "7s5c",
      "7s5h",
      "7s5d",
      "7h5c",
      "7h5s",
      "7h5d",
      "7d5c",
      "7d5s",
      "7d5h"
    ]
  },
  {
    "74o": [
      "7c4s",
      "7c4h",
      "7c4d",
      "7s4c",
      "7s4h",
      "7s4d",
      "7h4c",
      "7h4s",
      "7h4d",
      "7d4c",
      "7d4s",
      "7d4h"
    ]
  },
  {
    "73o": [
      "7c3s",
      "7c3h",
      "7c3d",
      "7s3c",
      "7s3h",
      "7s3d",
      "7h3c",
      "7h3s",
      "7h3d",
      "7d3c",
      "7d3s",
      "7d3h"
    ]
  },
  { "72s": ["7s2s", "7h2h", "7d2d", "7c2c"] },
  {
    "72o": [
      "7c2s",
      "7c2h",
      "7c2d",
      "7s2c",
      "7s2h",
      "7s2d",
      "7h2c",
      "7h2s",
      "7h2d",
      "7d2c",
      "7d2s",
      "7d2h"
    ]
  },
  {
    "64o": [
      "6c4s",
      "6c4h",
      "6c4d",
      "6s4c",
      "6s4h",
      "6s4d",
      "6h4c",
      "6h4s",
      "6h4d",
      "6d4c",
      "6d4s",
      "6d4h"
    ]
  },
  {
    "63o": [
      "6c3s",
      "6c3h",
      "6c3d",
      "6s3c",
      "6s3h",
      "6s3d",
      "6h3c",
      "6h3s",
      "6h3d",
      "6d3c",
      "6d3s",
      "6d3h"
    ]
  },
  {
    "62o": [
      "6c2s",
      "6c2h",
      "6c2d",
      "6s2c",
      "6s2h",
      "6s2d",
      "6h2c",
      "6h2s",
      "6h2d",
      "6d2c",
      "6d2s",
      "6d2h"
    ]
  },
  {
    "54o": [
      "5c4s",
      "5c4h",
      "5c4d",
      "5s4c",
      "5s4h",
      "5s4d",
      "5h4c",
      "5h4s",
      "5h4d",
      "5d4c",
      "5d4s",
      "5d4h"
    ]
  },
  {
    "53o": [
      "5c3s",
      "5c3h",
      "5c3d",
      "5s3c",
      "5s3h",
      "5s3d",
      "5h3c",
      "5h3s",
      "5h3d",
      "5d3c",
      "5d3s",
      "5d3h"
    ]
  },
  {
    "52o": [
      "5c2s",
      "5c2h",
      "5c2d",
      "5s2c",
      "5s2h",
      "5s2d",
      "5h2c",
      "5h2s",
      "5h2d",
      "5d2c",
      "5d2s",
      "5d2h"
    ]
  },
  {
    "43o": [
      "4c3s",
      "4c3h",
      "4c3d",
      "4s3c",
      "4s3h",
      "4s3d",
      "4h3c",
      "4h3s",
      "4h3d",
      "4d3c",
      "4d3s",
      "4d3h"
    ]
  },
  {
    "42o": [
      "4c2s",
      "4c2h",
      "4c2d",
      "4s2c",
      "4s2h",
      "4s2d",
      "4h2c",
      "4h2s",
      "4h2d",
      "4d2c",
      "4d2s",
      "4d2h"
    ]
  },
  {
    "32o": [
      "3c2s",
      "3c2h",
      "3c2d",
      "3s2c",
      "3s2h",
      "3s2d",
      "3h2c",
      "3h2s",
      "3h2d",
      "3d2c",
      "3d2s",
      "3d2h"
    ]
  }
];

export const COMBO_CELL_CLASS = "comboCell";
export const SUITED_CELL_CLASS = "suitedCell";
export const OFFSUIT_CELL_CLASS = "offsuitCell";
export const PP_CELL_CLASS = "pocketPairCell";
export const BUTTON_CLASS = "button";
export const EQUITY_CLASS = "equityDisplay";
export const BOARD_TITLE_CLASS = "boardTitle";
export const BOARD_ERROR_CLASS = "validationError";

const lookup = {};
lookup[COMBO_CELL_CLASS] = COMBO_CELL_CLASS + "Selected";
lookup[SUITED_CELL_CLASS] = SUITED_CELL_CLASS + "Selected";
lookup[OFFSUIT_CELL_CLASS] = OFFSUIT_CELL_CLASS + "Selected";
lookup[PP_CELL_CLASS] = PP_CELL_CLASS + "Selected";

lookup[COMBO_CELL_CLASS + "Selected"] = COMBO_CELL_CLASS;
lookup[OFFSUIT_CELL_CLASS + "Selected"] = OFFSUIT_CELL_CLASS;
lookup[SUITED_CELL_CLASS + "Selected"] = SUITED_CELL_CLASS;
lookup[PP_CELL_CLASS + "Selected"] = PP_CELL_CLASS;
export const CELL_CLASS_LOOKUP = lookup;

export const ActionTypes = {
  UPDATE_SPECIFIC_COMBO: "UPDATE_SPECIFIC_COMBO",
  REMOVE_SPECIFIC_COMBO: "REMOVE_SPECIFIC_COMBO",
  ADD_SPECIFIC_COMBO: "ADD_SPECIFIC_COMBO",
  SELECT_COMBOS: "SELECT_COMBOS",
  UNSELECT_COMBOS: "UNSELECT_COMBOS",
  SELECT_HAND: "SELECT_HAND",
  UNSELECT_HAND: "UNSELECT_HAND",
  UPDATE_BOARD: "UPDATE_BOARD",
  SET_INITIAL_RANGES: "SET_INITIAL_RANGES",
  UPDATE_MANY_COMBOS: "UPDATE_MANY_COMBOS",
  REPLACE_HAND_COMBOS: "REPLACE_HAND_COMBOS"
};

export const PLAYER_NAMES = [
  "Player 1",
  "Player 2",
  "Player 3",
  "Player 4",
  "Player 5",
  "Player 6",
  "Player 7",
  "Player 8",
  "Player 9"
];
export const PLAYER_NAME_SERIALIZER_LOOKUP = {
  "Player 1": "one",
  "Player 2": "two",
  "Player 3": "three",
  "Player 4": "four",
  "Player 5": "five",
  "Player 6": "six",
  "Player 7": "seven",
  "Player 8": "eight",
  "Player 9": "nine",
  "Your Range": "one",
  "Opponent Call Range": "two"
};
export const EV_BUTTON_NAMES = [
  "Your Range",
  "Opponent Total Range",
  "Opponent Call Range"
];

export const EV_CALL_RANGE = "Opponent Call Range";
export const EV_OPP_TOTAL_RANGE = "Opponent Total Range";
export const EV_YOUR_RANGE = "Your Range";
export const EV_EQUITY_DISPLAY_NAMES = [EV_YOUR_RANGE, EV_CALL_RANGE];
export const SHORT_PLAYER_NAMES = {
  "Player 1": "P1",
  "Player 2": "P2",
  "Player 3": "P3",
  "Player 4": "P4",
  "Player 5": "P5",
  "Player 6": "P6",
  "Player 7": "P7",
  "Player 8": "P8",
  "Player 9": "P9",
  "Your Range": "Hero",
  "Opponent Total Range": "Villain",
  "Opponent Call Range": "Villain"
};

export const RequestStatuses = {
  FULFILLED: "FULFILLED",
  PENDING: "PENDING",
  REJECTED: "REJECTED"
};

export const API_BASE = "/";

export const ValidationTypes = {
  VALID: "VALID",
  INVALID: "INVALID"
};

export const COMBO_URL_PARAM_NAME = "hrid";

export const ZINDEXES = { flyouts: 1000 };
