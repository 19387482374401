import React, { createContext, useReducer } from "react";
import {
  ActionTypes,
  PLAYER_NAMES,
  ValidationTypes,
  EV_OPP_TOTAL_RANGE,
  EV_CALL_RANGE
} from "../constants";
import {
  updateSpecificCombo,
  addOrRemoveCombos,
  updateManySpecificCombos,
  replaceHandCombos
} from "../actions";
import {
  createInitialRanges,
  createInitialPercentSelected
} from "../utils/combinations";
import { parseQueryString } from "../utils/parseQuery";

const validationType = {
  type: ValidationTypes.VALID,
  isValid: true,
  error: ""
};
export const InputValidationContext = createContext(validationType);
function validationReducer(validation, action) {
  return { type: action.type, error: action.error };
}

export const PlayerSelectedContext = createContext(PLAYER_NAMES[0]);
function playerSelectedReducer(buttonSelected, action) {
  return action.buttonSelected;
}

function percentSelectedReducer(percentsSelected, action) {
  const newPercentsSelected = { ...percentsSelected };
  newPercentsSelected[action.player] = action.newPercent;
  return newPercentsSelected;
}

const handRanges = createInitialRanges();
export const HandCombosContext = createContext({
  ...handRanges
});
function handleSelectedReducer(handCombos, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_SPECIFIC_COMBO:
      const newHandCombos = { ...handCombos };
      newHandCombos[action.player] = updateSpecificCombo(
        action.player,
        handCombos,
        action.suitCombo,
        action.remove,
        action.override
      );
      return newHandCombos;

    case ActionTypes.SELECT_HAND:
      // We want to merge hand combos here. We are only updating "selected"
      handCombos[action.player]["selected"] = action.selected;
      return { ...handCombos };

    case ActionTypes.SELECT_COMBOS:
      // We DON'T want to merge hand combos here. We sometimes remove combos.
      return {
        ...addOrRemoveCombos(
          action.player,
          handCombos,
          action.cards,
          action.cellClass,
          action.remove
        )
      };

    case ActionTypes.UPDATE_BOARD:
      return { ...handCombos, ...{ board: action.board } };

    case ActionTypes.SET_INITIAL_RANGES:
      return { ...parseQueryString(action.query) };

    case ActionTypes.UPDATE_MANY_COMBOS:
      // TODO: Make this work
      return updateManySpecificCombos(
        handCombos,
        action.newCombos,
        action.player
      );

    case ActionTypes.REPLACE_HAND_COMBOS:
      // TODO: Make this work
      return replaceHandCombos(handCombos, action.newCombos, action.player);

    default:
      return;
  }
}

const emailState = { isValid: true, email: "", submitted: false };
export const EmailContext = createContext(emailState);
function emailReducer(state, action) {
  return {
    email: action.email,
    isValid: action.isValid,
    submitted: action.submitted
  };
}

const Store = ({ children }) => {
  const [handCombos, comboDispatch] = useReducer(
    handleSelectedReducer,
    handRanges
  );
  const [playerSelected, playerDispatch] = useReducer(
    playerSelectedReducer,
    PLAYER_NAMES[0]
  );
  const [validation, validationDispatch] = useReducer(
    validationReducer,
    validationType
  );
  const [email, emailDispatch] = useReducer(emailReducer, emailState);
  return (
    <EmailContext.Provider value={[email, emailDispatch]}>
      <PlayerSelectedContext.Provider value={[playerSelected, playerDispatch]}>
        <HandCombosContext.Provider value={[handCombos, comboDispatch]}>
          <InputValidationContext.Provider
            value={[validation, validationDispatch]}
          >
            {children}
          </InputValidationContext.Provider>
        </HandCombosContext.Provider>
      </PlayerSelectedContext.Provider>
    </EmailContext.Provider>
  );
};

export default Store;
