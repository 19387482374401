import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.css";

import Guide from "./components/Guide";
import Toolbar from "./components/Toolbar";
import Store from "./context/store";
import Layout from "./components/Layout";
import EVChartLayout from "./components/EVChartLayout";
import ToolBar from "./components/Toolbar";
import RandomNumberGenerator from "./rng/RandomGenerator";

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
  }

  render() {
    return this.state.errorOccurred ? (
      <h1>Something went wrong!</h1>
    ) : (
      this.props.children
    );
  }
}

export class ChartHome extends React.Component {
  componentDidCatch() {
    // Todo: set state so we can render below an error message
    return;
  }
  render() {
    return (
      <Store>
        <Layout />
      </Store>
    );
  }
}

export class EVChartHome extends React.Component {
  render() {
    return (
      <Store>
        <ToolBar />
        <EVChartLayout />
      </Store>
    );
  }
}

export let GuidePage = () => (
  <>
    <Store>
      <Toolbar />
      <Guide />
    </Store>
  </>
);

const RandomNumberGeneratorWithContext = () => {
  return (
    <Store>
      <RandomNumberGenerator></RandomNumberGenerator>
    </Store>
  );
};

// TODO: Wrap error handler
function App() {
  return (
    <BrowserRouter basename="">
      <Switch>
        <Route path="/rng/" component={RandomNumberGeneratorWithContext} />
        <Route path="/guide" component={GuidePage} />
        <Route path="/ev" component={EVChartHome} />
        <Route exact path="/" component={ChartHome} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
