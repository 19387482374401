import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GoogleLogin, GoogleLogout } from "react-google-login";

import DrawerToggleButton from "./DrawerToggleButton";
import Backdrop from "./Backdrop";
import SideDrawer from "./SideDrawer";
import EmailSubmit from "./EmailSubmit";
import { storeTokenLocalStorage, removeLogin } from "../actions";
import { SOCIAL_AUTH_GOOGLE_OAUTH2_KEY, AUTH_TOKEN_NAME } from "../constants";
import "../styles/Toolbar.css";

const Toolbar = (props) => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className="toolbar__logo">
        <a href="/"> </a>
      </div>
      <div className="spacer" />
      <div className="toolbar_navigation-items">
        <ul>
          <li>
            <Link to="/">Calculator</Link>
          </li>
          <li>
            <Link to="/guide">Instructions</Link>
          </li>
        </ul>
      </div>
      <EmailSubmit />
    </nav>
  </header>
);

class ToolBarWrapper extends React.Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div style={{ height: "100%" }}>
        <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {backdrop}
      </div>
    );
  }
}

export default ToolBarWrapper;

function GoogleLoginButton(props) {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(AUTH_TOKEN_NAME)
  );
  const handleLogout = () => {
    removeLogin(setAccessToken);
  };
  const handleLogin = (googleAuthObj) => {
    storeTokenLocalStorage(googleAuthObj, setAccessToken);
  };
  return accessToken ? (
    <div>
      <GoogleLogout
        clientId={SOCIAL_AUTH_GOOGLE_OAUTH2_KEY}
        buttonText="Logout"
        onLogoutSuccess={handleLogout}
        onFailure={() => {
          console.log("error logging out");
          handleLogout();
        }}
      ></GoogleLogout>
    </div>
  ) : (
    <div>
      <GoogleLogin
        clientId={SOCIAL_AUTH_GOOGLE_OAUTH2_KEY}
        buttonText="Login with Google"
        onSuccess={handleLogin}
        onFailure={() => {
          console.log("error logging in");
          handleLogout();
        }}
        isSignedIn={true}
      />
    </div>
  );
}

export function ToolBar(props) {
  // TODO: Rewrite all this with style components ex: "styled.div`blah`"
  // TODO: Rewrite this without relying on nested css-grids
  const toolbarStyle = {
    minWidth: "100%",
    minHeight: "75%",
    display: "grid",
    gridTemplateColumns: "[email-submit] 3fr [login] 1fr [twitter-icon] 1fr",
    gridTemplateRows: "minmax(1fr)",
    background:
      "linear-gradient(90deg, rgba(39,50,56,1) 0%, rgba(31,33,34,1) 100%)",
    alignItems: "center",
  };
  const textStyle = {
    color: "white",
    textDecoration: "none",
    fontFamily:
      "Verdana, Geneva, DejaVu, Sans, Bitstream, Vera, Sans, Liberation, Sans, Verdana, Ref, sans-serif",
    fontSize: "1em",
    fontWeight: "600",
  };
  const emailStyle = {
    gridColumnStart: "email-submit",
    gridColumnEnd: "email-submit",
  };

  return (
    <div style={toolbarStyle}>
      <div style={{ ...textStyle, emailStyle }}>
        <EmailSubmit />
      </div>
      <GoogleLoginButton></GoogleLoginButton>
    </div>
  );
}
