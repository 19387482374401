import { isValidURLQuery } from "./validation";
import { createInitialRanges } from "./combinations";
import { COMBO_URL_PARAM_NAME } from "../constants";

export function parseQueryString(query) {
  if (query) {
    const params = new URLSearchParams(query);
    // Slice off ? mark
    const URIDecoded = decodeURIComponent(params.get(COMBO_URL_PARAM_NAME));
    const base64Decoded = atob(URIDecoded);
    return JSON.parse(base64Decoded);
  }
}

const defaultQS = JSON.stringify(createInitialRanges());

export function getValidURLQuery(selectedCombos) {
  const stringified = JSON.stringify(selectedCombos);
  if (stringified === defaultQS) {
    return "";
  }
  if (isValidURLQuery(selectedCombos)) {
    const params = new URLSearchParams();
    params.set(
      COMBO_URL_PARAM_NAME,
      encodeURIComponent(Buffer.from(stringified).toString("base64"))
    );
    return "?" + params.toString();
  }
  return "";
}
