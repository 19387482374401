import React, { useContext } from "react";

import "../styles/Chart.css";
import { ActionTypes } from "../constants";
import { HandCombosContext, PlayerSelectedContext } from "../context/store";
import { getNutFlushDraws, getBDNFD, getPP } from "../utils/combinations";

export function AddCombosButtons(props) {
  // This only ADDS combos, does not remove.
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [playerSelected, handleButtonDispatch] = useContext(
    PlayerSelectedContext
  );
  function handleAnyNFD(e) {
    // TODO: Wire this up correctly
    handleSelectedDispatch({
      type: ActionTypes.UPDATE_MANY_COMBOS,
      player: playerSelected,
      newCombos: getNutFlushDraws(selectedCombos["board"])
    });
  }
  function handleBDNFD(e) {
    handleSelectedDispatch({
      type: ActionTypes.UPDATE_MANY_COMBOS,
      player: playerSelected,
      newCombos: getBDNFD(selectedCombos["board"])
    });
  }
  function handlePP(e) {
    handleSelectedDispatch({
      type: ActionTypes.UPDATE_MANY_COMBOS,
      player: playerSelected,
      newCombos: getPP()
    });
  }
  const buttonStyle = {
    display: "grid",
    gridTemplateRows: "minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr) .5fr",
    minWidth: "100%",
    minHeight: "100%",
    gridRowGap: "4px"
  };
  return (
    <div style={buttonStyle}>
      <button className="button" onClick={handlePP}>
        Add Pocket Pairs
      </button>
      <button className="button" onClick={handleAnyNFD}>
        Add Ace high flush draws
      </button>
      <button className="button" onClick={handleBDNFD}>
        Add backdoor Ace high flush draws
      </button>
    </div>
  );
}
