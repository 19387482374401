export function EvCalc(heroEq, oppEq, foldEq, potPercentBetSize) {
  let ev = 0; //-potPercentBetSize;
  ev = -(1 - foldEq) * (1 - heroEq) * potPercentBetSize;
  ev = ev + (1 - foldEq) * heroEq * (potPercentBetSize + 1);
  return Math.round(100 * ev) / 100;
}

// -(1-0) * (1-.46) * 1
// + (1-0) * .46 * (1+1)
let potPercentBetSize = 1;
let eq = 0.46;
let fe = 0;
let callEvLose = (1 - fe) * (1 - eq) * potPercentBetSize;
let callEvWin = (1 - fe) * eq * potPercentBetSize + 1;
let foldWin = fe * 1;
