import {
  SUIT_LIST,
  SUITED_CELL_CLASS,
  PP_CELL_CLASS,
  ValidationTypes,
  RequestStatuses,
  EV_CALL_RANGE,
  EV_OPP_TOTAL_RANGE,
  AUTH_TOKEN_NAME,
} from "./constants";
import { tokenAuthFetch } from "./utils/auth";
import { validator } from "./utils/validation";
import { getSerializedRanges } from "./utils/serializing";
import isEmail from "validator/lib/isEmail";
import { getNutnewCombos } from "./utils/combinations";

export function getCombos(cardOne, cardTwo, cellClass) {
  // return all suit combos of hand combo
  // TODO: Change 10x to Tx
  const isSuited = cellClass.includes(SUITED_CELL_CLASS);
  const isPP = cellClass.includes(PP_CELL_CLASS);
  const combos = [];
  if (isPP) {
    // pocket pairs
    combos.push(cardOne + "c" + cardTwo + "h");
    combos.push(cardOne + "c" + cardTwo + "d");
    combos.push(cardOne + "c" + cardTwo + "s");
    combos.push(cardOne + "h" + cardTwo + "d");
    combos.push(cardOne + "h" + cardTwo + "s");
    combos.push(cardOne + "d" + cardTwo + "s");
  } else if (isSuited) {
    // suitedmncards
    combos.push(cardOne + "c" + cardTwo + "c");
    combos.push(cardOne + "h" + cardTwo + "h");
    combos.push(cardOne + "d" + cardTwo + "d");
    combos.push(cardOne + "s" + cardTwo + "s");
  } else {
    // Offsuited cards
    SUIT_LIST.forEach(function (suit) {
      SUIT_LIST.forEach(function (otherSuit) {
        suit !== otherSuit && combos.push(cardOne + suit + cardTwo + otherSuit);
      });
    });
  }
  return combos;
}

export function getSuitsOfCombos(cardsWithSuits) {
  cardsWithSuits = cardsWithSuits.map((combo) => {
    return combo.replace(/[0-9]/g, "");
  });
  return cardsWithSuits.map((combo) => {
    return combo.replace(/(T|Q|J|K|A)/g, "");
  });
}

function getHighlighted(selectedsCombos, newCombos, remove) {
  // return combos that should be highlighted
  // First get all the suit/offsuit combos without cards:
  selectedsCombos = getSuitsOfCombos(selectedsCombos);
  newCombos = getSuitsOfCombos(newCombos);
  // Then remove combos from highlighted or add unique combos to them
  if (remove) {
    return selectedsCombos.filter((combo) => !newCombos.includes(combo));
    //highlighted.filter(suitCombo => selectedsCombos.includes(suitCombo));
  } else {
    // Find all unselected and select them
    return selectedsCombos;
  }
}

function isEVCallRange(player) {
  return player === EV_CALL_RANGE;
}
function isEVOppTotalRange(player) {
  return player === EV_OPP_TOTAL_RANGE;
}
function combineNewAndCurrentCombos(newSelected, player, cards, combos) {
  const uniqueCombos = new Set([...newSelected[player][cards], ...combos]);
  newSelected[player][cards] = [...uniqueCombos];
  return newSelected;
}

function copiedOppTotalToCallRange(newSelected, cards, combos) {
  const uniqueCombos = new Set([
    ...newSelected[EV_OPP_TOTAL_RANGE][cards],
    ...combos,
  ]);
  newSelected[EV_CALL_RANGE][cards] = [...uniqueCombos];
  return newSelected;
}

export function addOrRemoveCombos(
  player,
  selectedCombos,
  cards,
  cellClass,
  remove
) {
  // Get card combos based (pair, offsuit, suited), and update those
  // in the selectedCombos object.
  // Make sure the hand has an array.
  const newSelected = { ...selectedCombos };
  if (!newSelected[player][cards]) {
    newSelected[player][cards] = [];
  }
  const combos = getCombos(cards[0], cards[1], cellClass);
  // Remove selected combos (note: selected could be offsuit or suited)
  if (remove) {
    if (isEVOppTotalRange(player)) {
      newSelected[EV_CALL_RANGE][cards] = newSelected[player][cards].filter(
        (value, index, arr) => !combos.includes(value)
      );
    }
    newSelected[player][cards] = newSelected[player][cards].filter(
      (value, index, arr) => !combos.includes(value)
    );
    return newSelected;
  } else {
    if (isEVCallRange(player)) {
      return { ...selectedCombos };
    }
    if (isEVOppTotalRange(player)) {
      // TODO: Perform update on both total range and call range
      const normallyUpdatedCombos = combineNewAndCurrentCombos(
        newSelected,
        player,
        cards,
        combos
      );
      return copiedOppTotalToCallRange(normallyUpdatedCombos, cards, combos);
    }
    // Add selected combos
    return combineNewAndCurrentCombos(newSelected, player, cards, combos);
  }
}

function getNewSuitCombos(player, selectedCombos, suitCombo, remove = false) {
  //TODO: Verify the newCombos[player].concat vs newCombos.concat

  const selectedHand = selectedCombos[player]["selected"];
  const cardCombo =
    selectedHand[0] + suitCombo[0] + selectedHand[1] + suitCombo[1];
  const newCombos = [];
  // Remove combo from old combos and maintain distinct combos
  if (remove) {
    // Must be unique
    return [
      ...new Set(
        newCombos.concat(
          selectedCombos[player][selectedHand].filter(
            (value, index, arr) => value !== cardCombo
          )
        )
      ),
    ];
  } else {
    // Add new combo to old combos and maintain distinct combos
    return [
      ...new Set(
        newCombos.concat(
          selectedCombos[player][selectedHand].concat([cardCombo])
        )
      ),
    ];
  }
}

function shouldNotUpdate(selectedCombos, player, suitCombo) {
  if (
    selectedCombos[player]["selected"].includes("o") &&
    suitCombo[0] === suitCombo[1]
  ) {
    return true;
  }
  // Don't allow offsuited combos for suited hands
  if (
    selectedCombos[player]["selected"].includes("s") &&
    suitCombo[0] !== suitCombo[1]
  ) {
    return true;
  }
  // Don't allow suited combos for pocket pairs
  if (
    selectedCombos[player]["selected"][0] ===
      selectedCombos[player]["selected"][1] &&
    suitCombo[0] === suitCombo[1]
  ) {
    return true;
  }
}
function skipEVCallRange(player, remove) {
  // If this is EV_OPP_CALL_RANGE and we aren't removing, skip it.
  if (player === EV_CALL_RANGE && !remove) {
    return true;
  }
}

export function updateSpecificCombo(
  player,
  selectedCombos,
  suitCombo,
  remove = false,
  override = false
) {
  // Using last selected hand and suit combo cell, update that
  // hand's chosen combos
  if (skipEVCallRange(player, remove) && !override) {
    return selectedCombos[player];
  }
  if (shouldNotUpdate(selectedCombos, player, suitCombo)) {
    return selectedCombos[player];
  }
  const newSelected = { ...selectedCombos[player] };
  newSelected[selectedCombos[player]["selected"]] = getNewSuitCombos(
    player,
    selectedCombos,
    suitCombo,
    remove
  );
  return newSelected;
}

export function fetchEquities(e, selectedCombos, validationDispatch, setState) {
  const formData = getSerializedRanges(selectedCombos);
  const validation = validator(formData["board"]);
  if (!validation.isValid) {
    validationDispatch({
      type: ValidationTypes.INVALID,
      error: validation.error,
    });
  } else {
    validationDispatch({ type: ValidationTypes.VALID, error: "" });
    setState({
      status: RequestStatuses.PENDING,
    });
    fetch("https://api.handcombos.com/api/v1/equity?format=json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      // We get the API response and receive data in JSON format...
      .then((response) => response.json())
      // ...then we update the users state if successful
      .then((data) => {
        if (data.results) {
          setState({
            equities: data["results"],
            status: RequestStatuses.FULFILLED,
          });
          return;
        }
        setState({
          status: RequestStatuses.REJECTED,
        });
      })
      // Catch any errors we hit and update the app
      .catch((error) => {
        setState({
          status: RequestStatuses.REJECTED,
        });
      });
  }
}

export function submitEmail(email) {
  // Email was already validated
  fetch("/api/v1/email/submit", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    // We don't care about the response
    .then((response) => response)
    // We don't care about any errors
    .catch((error) => error);
}

export function updateManySpecificCombos(selectedCombos, newCombos, player) {
  const newSelected = { ...selectedCombos };
  for (var hand in newCombos) {
    const handCombos = newCombos[hand];
    handCombos.forEach((handCombo) => {
      if (hand in newSelected[player]) {
        if (!newSelected[player][hand].includes(handCombo)) {
          newSelected[player][hand].push(handCombo);
        }
      } else {
        newSelected[player][hand] = [handCombo];
      }
    });
  }
  return newSelected;
}

export function replaceHandCombos(selectedCombos, newCombos, player) {
  const newSelected = { ...selectedCombos };
  newSelected[player] = newCombos;
  return newSelected;
}

export function storeTokenLocalStorage(googleAuthObj, setBackendVerifiedToken) {
  if (!googleAuthObj.tokenId) {
    throw "No access token given for google authentication";
  }
  // TODO: Handle response statuses like we do above
  fetch("/api/v1/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token_id: googleAuthObj.tokenId,
    }),
  })
    // We get the API response and receive data in JSON format...
    .then((response) => response.json())
    // ...then we update the users state
    .then((data) => {
      localStorage.setItem(AUTH_TOKEN_NAME, data.token);
      setBackendVerifiedToken(AUTH_TOKEN_NAME);
    })
    // Catch any errors we hit and update the app
    .catch((error) => {
      console.log("ERROR Authenticating:", error);
      removeLogin(setBackendVerifiedToken);
      return;
    });
}

export function removeLogin(setBackendVerifiedToken) {
  setBackendVerifiedToken(null);
  localStorage.removeItem(AUTH_TOKEN_NAME);
}
