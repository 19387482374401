// TODO: These functions are coupled with the actual constants values
import {
  EV_BUTTON_NAMES,
  PLAYER_NAMES,
  EV_OPP_TOTAL_RANGE,
  PLAYER_NAME_SERIALIZER_LOOKUP
} from "../constants";
export function getSerializedPlayer(player) {
  // NOTE: Opponent Total Range shouldn't get a calculation
  return "range_" + PLAYER_NAME_SERIALIZER_LOOKUP[player];
}

function combineHoleCards(range) {
  let holeCards = [];
  Object.values(range).forEach(handCombos => {
    if (!Array.isArray(handCombos)) {
      return;
    }
    holeCards = holeCards.concat(handCombos);
  });
  return holeCards;
}

function isEv(selectedCombos) {
  let combosExist = false;
  EV_BUTTON_NAMES.forEach(player => {
    const numCombos = Object.values(selectedCombos[player]).length;
    if (numCombos > 1) {
      combosExist = true;
    }
  });
  return combosExist;
}

function getCorrectPlayers(selectedCombos) {
  if (isEv(selectedCombos)) {
    return EV_BUTTON_NAMES;
  } else {
    return PLAYER_NAMES;
  }
}

export function getSerializedRanges(selectedCombos) {
  // TODO: BUG - Add combos to / path, then click calculate on /ev
  // path with hrid appended from / path
  // Return form data for equity API
  const formData = {};
  const playerRanges = Object.entries(selectedCombos);
  playerRanges.forEach(playersRange => {
    var player = playersRange[0];
    var range = playersRange[1];
    var correctPlayers = getCorrectPlayers(selectedCombos);
    if (!correctPlayers.includes(player)) {
      return;
    }
    if (player === EV_OPP_TOTAL_RANGE || player === "board") {
      return;
    }
    const playerName = getSerializedPlayer(player);
    const holeCards = combineHoleCards(range);
    formData[playerName] = holeCards.join();
  });
  formData["board"] = selectedCombos["board"];
  return formData;
}
