import React, { useContext } from "react";

import "../styles/Chart.css";
import { HandCombosContext, InputValidationContext } from "../context/store";
import {
  ActionTypes,
  BOARD_ERROR_CLASS,
  BOARD_TITLE_CLASS,
  ValidationTypes
} from "../constants";

export function BoardInput(props) {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [inputValdation, validationDispatch] = useContext(
    InputValidationContext
  );
  const gridStyle = {
    display: "grid",
    gridTemplateRows: "5px minmax(0, 26px) minmax(0, 26px)",
    gridTemplateColumns: "25% 75%",
    rowGap: "1px",
    columnGap: "5px",
    minWidth: "0%",
    minHeight: "0%"
  };
  const inputStyle = {
    gridColumn: 2,
    gridRow: 2,
    border: "solid",
    width: "75%",
    borderColor: "#b9b9b9"
  };
  const validationStyle = {
    gridColumn: "1 / 4",
    gridRow: 3,
    width: "100%",
    color: "#d03234",
    //overflow: "scroll",
    whiteSpace: "nowrap"
  };
  function handleChange(e) {
    // TODO: Form validation
    handleSelectedDispatch({
      type: ActionTypes.UPDATE_BOARD,
      board: e.target.value
    });
  }
  const isValid = inputValdation.type === ValidationTypes.VALID;
  const boardText = "Board:";
  const titleColor = isValid ? "black" : "#d03234";
  const inputValue = selectedCombos["board"];
  const titleStyle = {
    gridColumn: 1,
    gridRow: 2,
    textAlign: "left",
    color: titleColor,
    minWidth: "0%",
    minHeight: "0%"
  };
  return (
    <div style={gridStyle}>
      <span className={BOARD_TITLE_CLASS} style={titleStyle}>
        {boardText}
      </span>
      <input
        placeholder="ex: AsKcQdTh9s"
        value={inputValue}
        style={inputStyle}
        onChange={handleChange}
      />
      <span className={BOARD_ERROR_CLASS} style={validationStyle}>
        {inputValdation.error}
      </span>
    </div>
  );
}
