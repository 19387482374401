import React from "react";

import { Chart, ComboChart } from "./Chart";
import { EvEquityDisplay, DisplayRanges } from "./RightSideBar";
import { PlayerButtons } from "./PlayerButtons";
import { BoardInput } from "./BoardInput";
import { EV_BUTTON_NAMES } from "../constants";

export default function EVChartLayout(props) {
  const chartSqSize = 49;
  const chartMinSize = "640px";
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `[left-chart-gap] 5% [chart-start] minmax(${chartMinSize}, ${chartSqSize}%) [right-chart-gap] 5% [side-bar-start] minmax(230px, 230px)[right-end-gap] minmax(5px,5px)`,
    gridTemplateRows: `[navbar-start] 76px [chart-row-start] 860px [players-row-start] 30px [footer] minmax(0%, 100%)`,
    gridColumnGap: "5px",
    gridRowGap: "5px",
    minHeight: "90vh",
    minWidth: "90vh"
  };
  const chartAreaStyle = {
    gridColumnStart: "chart-start",
    gridColumnEnd: "chart-start",
    gridRow: "chart-row-start / chart-row-start",
    display: "grid",
    minWidth: "0%",
    minHeight: "0%"
  };
  const playerButtonStyle = {
    gridRowStart: "players-row-start",
    gridRowEnd: "players-row-start",
    gridColumnStart: "chart-start",
    gridColumnEnd: "chart-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  const sidebarWrapperStyle = {
    gridAutoRows: "max-content",
    gridColumnStart: "side-bar-start",
    girdColumnEnd: "side-bar-start",
    gridRow: "1 / -1",
    display: "grid",
    gridTemplateRows:
      "[board-start] minmax(48px, .5fr) [combo-row-start] 120px [equity-start] minmax(0, 4fr) [display-start] minmax(0, 2fr)",
    minWidth: "0%",
    minHeight: "0%",
    gridRowGap: ".25rem"
    //border: "solid #1e2930"
  };
  const comboStyle = {
    gridRowStart: "combo-row-start",
    gridRowEnd: "combo-row-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  const boardStyle = {
    gridRowStart: "board-start",
    gridRowEnd: "board-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  const sidebarStyle = {
    gridRowStart: "equity-start",
    gridRowEnd: "equity-start",
    display: "grid",
    minWidth: "0%",
    minHeight: "0%"
  };
  const displayStyle = {
    gridRowStart: "display-start",
    gridRowEnd: "display-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  return (
    <div style={gridStyle}>
      <div style={chartAreaStyle}>
        <Chart />
      </div>
      <div style={playerButtonStyle}>
        <PlayerButtons isEv={true} buttonNames={EV_BUTTON_NAMES} />
      </div>
      <div style={sidebarWrapperStyle}>
        <div style={boardStyle}>
          <BoardInput />
        </div>
        <div style={comboStyle}>
          <ComboChart />
        </div>
        <div style={sidebarStyle}>
          <EvEquityDisplay />
        </div>
        <div style={displayStyle}>
          <DisplayRanges />
        </div>
      </div>
    </div>
  );
}
