import {
  POCKET_PAIRS,
  PLAYER_NAMES,
  EV_BUTTON_NAMES,
  EV_CALL_RANGE,
  EV_OPP_TOTAL_RANGE,
  EV_YOUR_RANGE,
  SUIT_LIST,
  CARD_LIST
} from "../constants";

export function getCombos(selectedCombos, playerSelected) {
  // TODO: get rid of selected from this check, also allow combo lists <=2

  let combos = [];
  const selected = selectedCombos[playerSelected]["selected"];
  function filterNonCombos(value, idx, arr) {
    return value !== selected;
  }
  combos = combos.concat(
    Object.values(selectedCombos[playerSelected]).filter(filterNonCombos)
  );
  combos = [].concat.apply([], combos).join();
  return combos;
}

export function createInitialRanges() {
  const handRanges = {};
  for (const key of PLAYER_NAMES) {
    handRanges[key] = { selected: "" };
  }
  handRanges["board"] = "";
  for (const key of EV_BUTTON_NAMES) {
    handRanges[key] = { selected: "" };
  }
  return handRanges;
}

function comboBlocked(combo, board) {
  for (var i = 0, charsLength = combo.length; i < charsLength; i += 2) {
    if (board.includes(combo.substring(i, i + 2))) {
      return true;
    }
  }
}
function getUniqueValidCombos(handComboDict, board) {
  const handCombos = new Set();
  Object.values(handComboDict).forEach(comboList => {
    if (Array.isArray(comboList)) {
      comboList.forEach(hand => {
        if (!comboBlocked(hand, board)) {
          handCombos.add(hand);
        }
      });
    }
  });
  return handCombos;
}

export function calcFoldEquity(selectedCombos) {
  let deadCards = selectedCombos["board"];
  // get dead hero cards if only 1 hand
  const heroCards = getUniqueValidCombos(
    selectedCombos[EV_YOUR_RANGE],
    deadCards
  );
  if (heroCards.size == 1) {
    deadCards = deadCards + Array.from(heroCards)[0];
  }
  const callRange = getUniqueValidCombos(
    selectedCombos[EV_CALL_RANGE],
    deadCards
  );
  const totalRange = getUniqueValidCombos(
    selectedCombos[EV_OPP_TOTAL_RANGE],
    deadCards
  );
  return (totalRange.size - callRange.size) / totalRange.size;
}

function isTurn(board) {
  return board.length > 6;
}

export function getNutFlushDraws(board) {
  // Board is 5 cards, get flush draws that existed by the turn.
  if (board.length === 10) {
    return getNutFlushDraws(board.slice(0, 8));
  }
  let suitCardCountCheck = 2;
  const flushDraws = {};
  const suitCounts = {};
  SUIT_LIST.forEach(suit => {
    suitCounts[suit] = 0;
  });
  [...board].forEach(letter => {
    if (letter in suitCounts) {
      suitCounts[letter] += 1;
    }
  });
  for (var suit in suitCounts) {
    if (suitCounts[suit] === suitCardCountCheck) {
      CARD_LIST.forEach(card => {
        if (card != "A") {
          flushDraws["A" + card + "s"] = ["A" + suit + card + suit];
        }
      });
    }
  }
  return flushDraws;
}

export function getBDNFD(board) {
  // Board is 5 cards, get flush draws that existed by the turn.
  if (board.length == 10) {
    return getNutFlushDraws(board.slice(0, 8));
  }
  let suitCardCountCheck = 1;
  const flushDraws = {};
  const suitCounts = {};
  SUIT_LIST.forEach(suit => {
    suitCounts[suit] = 0;
  });
  [...board].forEach(letter => {
    if (letter in suitCounts) {
      suitCounts[letter] += 1;
    }
  });
  // TODO: Implement backdoor flush draws that became flush draws on the turn.
  for (var suit in suitCounts) {
    if (suitCounts[suit] == suitCardCountCheck && !isTurn(board)) {
      CARD_LIST.forEach(card => {
        if (card != "A") {
          flushDraws["A" + card + "s"] = ["A" + suit + card + suit];
        }
      });
    }
  }
  return flushDraws;
}

export function getPP() {
  const pocketPairs = {};
  POCKET_PAIRS.forEach(pocketPair => {
    pocketPairs[pocketPair] = [];
    const cardOne = pocketPair[0];
    const cardTwo = pocketPair[1];
    pocketPairs[pocketPair].push(cardOne + "c" + cardTwo + "h");
    pocketPairs[pocketPair].push(cardOne + "c" + cardTwo + "d");
    pocketPairs[pocketPair].push(cardOne + "c" + cardTwo + "s");
    pocketPairs[pocketPair].push(cardOne + "h" + cardTwo + "d");
    pocketPairs[pocketPair].push(cardOne + "h" + cardTwo + "s");
    pocketPairs[pocketPair].push(cardOne + "d" + cardTwo + "s");
  });
  return pocketPairs;
}
