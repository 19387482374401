import React, { useContext } from "react";

import "rc-slider/assets/index.css";
import Slider, { createSliderWithTooltip } from "rc-slider";
import {
  ORDERED_STARTING_HAND_COMBOS,
  PP_CELL_CLASS,
  SUITED_CELL_CLASS,
  OFFSUIT_CELL_CLASS,
  ActionTypes
} from "../constants";
import { getCombos } from "../actions";
import { HandCombosContext, PlayerSelectedContext } from "../context/store";

const SliderWithTooltip = createSliderWithTooltip(Slider);

function getOrderedCombos(topPercent) {
  const topHandIdx = Math.round(1326 * (topPercent / 100));
  const topHandCombosList = [];
  let totalCombos = 0;
  for (let handCombo of ORDERED_STARTING_HAND_COMBOS) {
    topHandCombosList.push(handCombo);
    totalCombos += Object.values(handCombo)[0].length;
    if (totalCombos + 4 >= topHandIdx) {
      break;
    }
  }
  const topHandsCombos = {};
  topHandCombosList.forEach(topHandCombos => {
    Object.assign(topHandsCombos, topHandCombos);
  });
  topHandsCombos["selected"] = Object.keys(
    topHandCombosList[topHandCombosList.length - 1]
  )[0];

  return topHandsCombos;
}

export function VerticalSlider() {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [playerSelected, handlePlayerDispatch] = useContext(
    PlayerSelectedContext
  );
  function handleSliderOnChange(topPercent) {
    handleSelectedDispatch({
      type: ActionTypes.REPLACE_HAND_COMBOS,
      player: playerSelected,
      newCombos: getOrderedCombos(topPercent)
    });
  }
  const actualPercentOfHands =
    (Object.values(selectedCombos[playerSelected]).reduce(
      (count, row) => count + row.length,
      0
    ) /
      1329) *
    100;

  return (
    <SliderWithTooltip
      value={actualPercentOfHands}
      vertical
      onChange={handleSliderOnChange}
      marks={{
        10: 10,
        20: 20,
        30: 30,
        40: 40,
        50: 50,
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100
      }}
    />
  );
}
