import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

import "../styles/Chart.css";
import {
  CARD_LIST,
  SUIT_LIST,
  COMBO_CELL_CLASS,
  SUITED_CELL_CLASS,
  OFFSUIT_CELL_CLASS,
  PP_CELL_CLASS,
  CELL_CLASS_LOOKUP,
  ActionTypes,
  COMBO_URL_PARAM_NAME,
  EV_OPP_TOTAL_RANGE,
  EV_CALL_RANGE
} from "../constants";
import { getSuitsOfCombos } from "../actions";
import { HandCombosContext, PlayerSelectedContext } from "../context/store";
import { Club, Heart, Spade, Diamond } from "./Suits";
import { getValidURLQuery } from "../utils/parseQuery";

function isSuitCard(card) {
  return SUIT_LIST.includes(card);
}
function getSuitString(cellClass) {
  return cellClass.includes(OFFSUIT_CELL_CLASS) ? "o" : "s";
}
function getCards(cardOne, cardTwo, idxOne, idxTwo) {
  return isSuitCard(cardOne)
    ? cardOne + cardTwo
    : cardOne === cardTwo
    ? cardOne + cardTwo
    : idxOne <= idxTwo
    ? cardOne + cardTwo + getSuitString(SUITED_CELL_CLASS)
    : cardTwo + cardOne + getSuitString(OFFSUIT_CELL_CLASS);
}
function getCellClass(playerCombos, cardOne, cardTwo, idxOne, idxTwo) {
  // Cards now have suit string
  const cards = getCards(cardOne, cardTwo, idxOne, idxTwo);
  const selected = playerCombos["selected"];
  if (isSuitCard(cardOne)) {
    if (selected && getSuitsOfCombos(playerCombos[selected]).includes(cards)) {
      return CELL_CLASS_LOOKUP[COMBO_CELL_CLASS];
    } else {
      return COMBO_CELL_CLASS;
    }
    // In selected
  } else if (cards in playerCombos && playerCombos[cards].length > 0) {
    // isPP, is suited, is offsuited and selected
    if (cardOne === cardTwo) {
      return CELL_CLASS_LOOKUP[PP_CELL_CLASS];
    } else if (idxOne <= idxTwo) {
      return CELL_CLASS_LOOKUP[SUITED_CELL_CLASS];
    } else {
      return CELL_CLASS_LOOKUP[OFFSUIT_CELL_CLASS];
    }
  } // Not selected & isPP, is suited, is offsuited
  else {
    if (cardOne === cardTwo) {
      return PP_CELL_CLASS;
    } else if (idxOne <= idxTwo) {
      return SUITED_CELL_CLASS;
    } else {
      return OFFSUIT_CELL_CLASS;
    }
  }
}

function Cell(props) {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [playerSelected, handleButtonDispatch] = useContext(
    PlayerSelectedContext
  );
  const { cardOne, cardTwo, idxOne, idxTwo } = props;

  function handleSelected(e) {
    const cellClass = e.target.className;
    const cardsWithSuit = getCards(cardOne, cardTwo, idxOne, idxTwo);
    // Update selected combos and selected hand
    handleSelectedDispatch({
      type: ActionTypes.SELECT_HAND,
      selected: cardsWithSuit,
      player: playerSelected
    });
    handleSelectedDispatch({
      type: ActionTypes.SELECT_COMBOS,
      cards: cardsWithSuit,
      cellClass: cellClass,
      remove: cellClass.includes("Selected"),
      player: playerSelected
    });
    if (playerSelected === EV_OPP_TOTAL_RANGE) {
      // Mimic total range to call range
      handleSelectedDispatch({
        type: ActionTypes.SELECT_HAND,
        selected: cardsWithSuit,
        player: EV_CALL_RANGE
      });
      handleSelectedDispatch({
        type: ActionTypes.SELECT_COMBOS,
        cards: cardsWithSuit,
        cellClass: cellClass,
        remove: cellClass.includes("Selected"),
        player: EV_CALL_RANGE
      });
    }
  }

  const text = getCards(cardOne, cardTwo, idxOne, idxTwo);
  const shouldHighlight = selectedCombos[playerSelected]["selected"] === text;
  const cellClass = getCellClass(
    selectedCombos[playerSelected],
    cardOne,
    cardTwo,
    idxOne,
    idxTwo
  );
  const cellStyle = {
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    paddingLeft: "5px",
    textAlign: "left",
    border: shouldHighlight ? "4px solid #FFDC00" : ""
  };

  return (
    <div style={cellStyle} onClick={handleSelected} className={cellClass}>
      {text}
    </div>
  );
}

export const Chart = withRouter(props => {
  const { location, history } = props;
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  // Set initial state
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get(COMBO_URL_PARAM_NAME)) {
      handleSelectedDispatch({
        type: ActionTypes.SET_INITIAL_RANGES,
        query: location.search
      });
    }
  }, []);

  useEffect(() => {
    const newURLQuery = getValidURLQuery(selectedCombos);
    if (location.search !== newURLQuery) {
      history.push(location.pathname + newURLQuery);
    }
  });

  // Nested for loop for all 169 starting hands
  const cardCells = CARD_LIST.map((cardOne, idxOne) =>
    CARD_LIST.map((cardTwo, idxTwo) => (
      <Cell
        cardOne={cardOne}
        cardTwo={cardTwo}
        idxOne={idxOne}
        idxTwo={idxTwo}
        key={cardOne + cardTwo + idxOne + idxTwo}
      />
    ))
  );
  const cellsStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(13, minmax(0,1fr))",
    gridTemplateRows: "repeat(13, minmax(0,1fr))",
    gridGap: "1px",
    minWidth: "0%",
    minHeight: "0%"
  };
  return <div style={cellsStyle}>{cardCells}</div>;
});

function ComboCell(props) {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [playerSelected, handleButtonDispatch] = useContext(
    PlayerSelectedContext
  );
  const { cardOne, cardTwo, idxOne, idxTwo } = props;

  function handleSelected(e) {
    // invert it's class to selected / unselected
    if (!selectedCombos[playerSelected]["selected"]) {
      return;
    }
    const cellClass = e.target.className;
    //e.target.className = CELL_CLASS_LOOKUP[cellClass];
    const cardsWithSuit = getCards(cardOne, cardTwo, idxOne, idxTwo);
    // Remove or add specifc combo to chose hand combos
    handleSelectedDispatch({
      type: ActionTypes.UPDATE_SPECIFIC_COMBO,
      suitCombo: cardsWithSuit,
      remove: cellClass.includes("Selected"),
      player: playerSelected
    });
    if (playerSelected === EV_OPP_TOTAL_RANGE) {
      handleSelectedDispatch({
        type: ActionTypes.UPDATE_SPECIFIC_COMBO,
        suitCombo: cardsWithSuit,
        remove: cellClass.includes("Selected"),
        player: EV_CALL_RANGE,
        override: true
      });
    }
  }

  const cellClass = getCellClass(
    selectedCombos[playerSelected],
    cardOne,
    cardTwo,
    idxOne,
    idxTwo
  );
  const lookup = { c: Club, h: Heart, s: Spade, d: Diamond };
  const SuitComponentOne = lookup[cardOne];
  const SuitComponentTwo = lookup[cardTwo];
  return (
    <>
      <div onClick={handleSelected} className={cellClass}>
        <SuitComponentOne />
        <SuitComponentTwo />
      </div>
    </>
  );
}
export const ComboChart = withRouter(props => {
  const chartStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr)",
    gridTemplateRows: "repeat(4, minmax(0, 1fr))",
    gridGap: "1px",
    minWidth: "0%",
    minHeight: "0%"
  };

  // Nested for loop for all 16 combos of suited+offsuited
  const cardCells = SUIT_LIST.map((cardOne, idxOne) =>
    SUIT_LIST.map((cardTwo, idxTwo) => (
      <ComboCell
        cardOne={cardOne}
        cardTwo={cardTwo}
        idxOne={idxOne}
        idxTwo={idxTwo}
        key={cardOne + cardTwo + idxOne + idxTwo}
      />
    ))
  );
  return <div style={chartStyle}>{cardCells}</div>;
});
