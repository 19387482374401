import React from "react";

export default function ShareIcon({ fill = "gray", ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      {...props}
      //style={{ textAlign: "center" }}
    >
      <path
        d="M20.5 9.6C20.5 8.53961 19.6046 7.68 18.5 7.68H15.25C15.1119 7.68 15 7.78745 15 7.92V9.36C15 9.49255 15.1119 9.6 15.25 9.6H18C18.2761 9.6 18.5 9.8149 18.5 10.08V21.6C18.5 21.8651 18.2761 22.08 18 22.08H6C5.72386 22.08 5.5 21.8651 5.5 21.6V10.08C5.5 9.8149 5.72386 9.6 6 9.6H8.75C8.81648 9.6 8.88022 9.57458 8.92713 9.52937C8.97404 9.48415 9.00027 9.42286 9 9.35904V7.91904C8.99974 7.85522 8.97301 7.79413 8.92572 7.74927C8.87843 7.70442 8.81448 7.67949 8.748 7.68H5.5C4.39543 7.68 3.5 8.53961 3.5 9.6V22.08C3.5 23.1404 4.39543 24 5.5 24H18.5C19.6046 24 20.5 23.1404 20.5 22.08V9.6Z"
        fill={fill}
      />
      <path
        d="M10.5 11.52C10.5 12.3153 11.1715 12.96 12 12.96C12.8284 12.96 13.5 12.3153 13.5 11.52V6.47902C13.5 6.34648 13.6119 6.23902 13.75 6.23902H15.5C15.9031 6.23624 16.2651 6.00136 16.418 5.64329C16.571 5.28521 16.485 4.87399 16.2 4.6003L12.7 1.2403C12.3095 0.865537 11.6765 0.865537 11.286 1.2403L7.78597 4.6003C7.49843 4.87563 7.41289 5.29056 7.56953 5.65027C7.72616 6.00999 8.09386 6.24303 8.49997 6.23998H10.25C10.388 6.23998 10.5 6.34743 10.5 6.47998V11.52Z"
        fill={fill}
      />
    </svg>
  );
}
