import React from "react";

function Header(props) {
  const headerStyle = {
    gridColumnStart: 3,
    gridColumnEnd: 4,
    textAlign: "center"
  };
  return (
    <>
      <h2 style={headerStyle}>How to use this equity range chart</h2>
    </>
  );
}

function BasicInstructions(props) {
  const bodyStyle = {
    gridColumnStart: 2,
    gridColumnEnd: 4,
    gridRowStart: 2,
    gridRowEnd: 3,
    textAlign: "left"
  };
  const listStyle = {
    lineHeight: "1.5em"
  };
  return (
    <div style={bodyStyle}>
      <h5>Basic instructions:</h5>
      <span>
        <ol style={listStyle}>
          <li>Choose a player</li> <li>Click on hand ranges</li>
          <li>Choose individual suits of hands </li>
          <li>Repeat for another player </li>
          <li>
            (Optional) Add cards to the board (only 3, 4 or 5 cards accepted)
          </li>
          <li>
            Click the "Calculate" button to calculate to compare your equity
          </li>
        </ol>
      </span>
    </div>
  );
}

function BoardCardHeader(props) {
  const bodyStyle = {
    gridColumnStart: 2,
    gridColumnEnd: 4,
    gridRowStart: 3,
    gridRowEnd: 4,
    textAlign: "left"
  };
  const boardCardStyle = {};
  return (
    <div style={bodyStyle}>
      <h5>Valid board card inputs:</h5>
    </div>
  );
}

function BoardCards(props) {
  const boardCardStyle = {
    gridColumnStart: 3,
    gridColumnEnd: 4,
    gridRowStart: 4,
    gridRowEnd: 5,
    textAlign: "left",
    lineHeight: "1.5em",
    fontWeight: "bold"
  };
  return (
    <div style={boardCardStyle}>
      <span style={{ color: "#3f9154" }}>
        Ac Kc Jc Tc 9c 8c 7c 6c 5c 4c 3c 2c
      </span>
      <br />
      <span style={{ color: "#5773f1" }}>
        Ad Kd Jd Td 9d 8d 7d 6d 5d 4d 3d 2d
      </span>
      <br />
      <span style={{ color: "#ff0000" }}>
        Ah Kh Jh Th 9h 8h 7h 6h 5h 4h 3h 2h
      </span>
      <br />
      <span style={{ color: "#000000" }}>
        As Ks Js Ts 9s 8s 7s 6s 5s 4s 3s 2s
      </span>
    </div>
  );
}

function BoardCardsExplained(props) {
  const boardCardStyle = {
    gridColumnStart: 2,
    gridColumnEnd: 4,
    gridRowStart: 5,
    gridRowEnd: 6,
    display: "flex",
    alignItems: "center"

    //ineHeight: "1.5em"
  };
  return (
    <div style={boardCardStyle}>
      You may only input three, four or five board cards. Three represents the
      flop. Four represents the flop + turn. Five represents the flop + turn +
      river.
    </div>
  );
}

export default function Guide(props) {
  const wrapperStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 4fr 1fr 1fr",
    gridTemplateRows: "repeat(5, .4fr)"
  };
  return (
    <>
      <div style={wrapperStyle}>
        <Header />
        <BasicInstructions />
        <BoardCardHeader />
        <BoardCards />
        <BoardCardsExplained />
      </div>
    </>
  );
}
