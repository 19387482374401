import { VALID_HOLE_CARDS, PLAYER_NAMES } from "../constants";

export function validator(boardInput) {
  // Empty board is valid
  if (boardInput.length === 0) {
    return { isValid: true };
  }
  // Must have 3,4 or 5 cards
  if (![6, 8, 10].includes(boardInput.length)) {
    return { isValid: false, error: "Must have 3, 4, or 5 board cards." };
  }
  // Split input by every second character. if not in list of correct cards
  // return false
  const cardArray = boardInput.match(/.{2}/g);
  for (var i = 0; i < cardArray.length; i++) {
    const card = cardArray[i];
    if (!VALID_HOLE_CARDS.has(card)) {
      return {
        isValid: false,
        error:
          "Invalid board card(s). See instructions (AQKJT must be capitalized)"
      };
    }
  }
  return { isValid: true };
}

export function isValidURLQuery(queryObject) {
  // Make sure all player names and board are  in queryObject
  const requiredKeys = ["board"].concat(PLAYER_NAMES);
  const isValidKey = name => {
    return name in queryObject;
  };
  if (!requiredKeys.every(isValidKey)) {
    return false;
  }
  return true;
}
