import React, { useContext, useEffect } from "react";

import { EmailContext } from "../context/store";
import { submitEmail } from "../actions";
import validator from "validator";

export default function EmailSubmit(props) {
  const [email, emailDispatch] = useContext(EmailContext);
  function handleChange(e) {
    //Validation happens on submit
    emailDispatch({ email: e.target.value, isValid: true, submitted: false });
  }
  function handleClick(e) {
    if (!validator.isEmail(email.email)) {
      emailDispatch({ isValid: false, email: "", submitted: false });
    } else {
      emailDispatch({ isValid: true, email: email.email, submitted: true });
      submitEmail(email.email);
    }
  }
  // Clear once submitted
  useEffect(() => {
    let timeout;
    if (email.submitted) {
      timeout = setTimeout(() => {
        emailDispatch({ isValid: true, email: "", submitted: false });
      }, 1400);
    }
    if (!email.isValid) {
      timeout = setTimeout(() => {
        emailDispatch({
          isValid: true,
          email: "",
          submitted: false
        });
      }, 1700);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [email]);

  const CTAStyle = {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    alignSelf: "right"
  };
  const CTATextStyle = {
    color: "white",
    paddingRight: "10px"
  };
  const inputStyle = { paddingRight: "10px" };
  const buttonStyle = { marginLeft: "10px" };
  const validationStyle = {
    paddingLeft: "10px",
    color: email.submitted ? "#daffba" : email.isValid ? "white" : "#d03234"
  };
  function handleEnter(e) {
    if (e.keyCode == 13) {
      handleClick(e);
    }
  }
  return (
    <div style={CTAStyle}>
      <span style={CTATextStyle}>Want it on mobile? Get notified:</span>
      <div>
        <input
          style={inputStyle}
          placeholder="email@example.com"
          onChange={handleChange}
          value={email.email}
          onKeyDown={handleEnter}
        />
        <button style={buttonStyle} className="button" onClick={handleClick}>
          Submit
        </button>
        <span style={validationStyle}>
          {email.submitted
            ? "You'll hear from us soon!"
            : !email.isValid
            ? "Invalid email"
            : ""}
        </span>
      </div>
    </div>
  );
}
