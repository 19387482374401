import React, { useContext, useEffect } from "react";

import "../styles/Chart.css";
import { PLAYER_NAMES, EV_BUTTON_NAMES } from "../constants";
import { PlayerSelectedContext } from "../context/store";

export function PlayerButton(props) {
  const [buttonSelected, handleButtonDispatch] = useContext(
    PlayerSelectedContext
  );
  const { name } = props;

  const playerStyle = { height: "100%" };
  const className = buttonSelected === name ? "buttonSelected" : "button";
  function handleButton() {
    handleButtonDispatch({ buttonSelected: name });
  }
  return (
    <button style={playerStyle} className={className} onClick={handleButton}>
      {name}
    </button>
  );
}

export const PlayerButtons = ({ buttonNames, isEv }) => {
  const numPlayers = buttonNames.length;
  const buttonsStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(" + numPlayers + ", minmax(0,1fr))",
    gridGap: "1em",
    minWidth: "0%",
    minHeight: "0%"
  };
  const [buttonSelected, handleButtonDispatch] = useContext(
    PlayerSelectedContext
  );
  useEffect(() => {
    if (isEv) {
      handleButtonDispatch({ buttonSelected: EV_BUTTON_NAMES[0] });
    }
  }, []);
  return (
    <div style={buttonsStyle}>
      {buttonNames.map(name => {
        return <PlayerButton key={name} name={name} />;
      })}
    </div>
  );
};

PlayerButtons.defaultProps = {
  buttonNames: PLAYER_NAMES
};
