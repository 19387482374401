//TODO: Move selected display above combo chart so it's more obvious

import React, { useContext, useState, useEffect } from "react";
import * as clipboard from "clipboard-polyfill";

import {
  HandCombosContext,
  PlayerSelectedContext,
  InputValidationContext
} from "../context/store";
import {
  EQUITY_CLASS,
  PLAYER_NAMES,
  SHORT_PLAYER_NAMES,
  EV_EQUITY_DISPLAY_NAMES,
  RequestStatuses,
  EV_YOUR_RANGE,
  EV_CALL_RANGE
} from "../constants";
import { fetchEquities } from "../actions";
import { EvCalc } from "../utils/ev";
import { getSerializedPlayer } from "../utils/serializing";
import { getCombos, calcFoldEquity } from "../utils/combinations";
import spinner from "../static/loading.gif";
import ShareIcon from "./ShareIcon";

function parsePlayerEquity(player, equityData, raw = false) {
  const equity = Math.round(equityData[getSerializedPlayer(player)] * 100);
  if (raw) {
    return equityData[getSerializedPlayer(player)];
  }
  return equity ? equity + "%" : "_";
}

function parseFoldEquity(foldEquity, raw = false) {
  const equity = Math.round(foldEquity * 100);
  if (raw) {
    return foldEquity;
  }
  return equity ? equity + "%" : "_";
}

function Row(props) {
  const { player, equities } = props;
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const rowStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    minWidth: "0%",
    minHeight: "0%",
    alignItems: "center",
    textOverflow: "ellipsis"
  };
  const percentageStyle = { textAlign: "center" };
  const playerStyle = { textAlign: "center" };
  const equity =
    equities.status === RequestStatuses.FULFILLED
      ? parsePlayerEquity(player, equities.equities)
      : "_";
  const isLoading = equities.status === RequestStatuses.PENDING;
  const isError = equities.status === RequestStatuses.REJECTED;
  const loadingStyle = { width: "15px", height: "15px" };
  const playerHasCombos = getCombos(selectedCombos, player);
  return (
    <div style={rowStyle}>
      <div style={playerStyle}>{SHORT_PLAYER_NAMES[player]}</div>
      {isLoading && playerHasCombos ? (
        <div className={EQUITY_CLASS} style={percentageStyle}>
          <img alt="loading" style={loadingStyle} src={spinner} />
        </div>
      ) : isError && playerHasCombos ? (
        <div className={EQUITY_CLASS} style={percentageStyle}>
          ERR
        </div>
      ) : (
        <div className={EQUITY_CLASS} style={percentageStyle}>
          {equity}
        </div>
      )}
    </div>
  );
}

export default function CalcAndShareButtons(props) {
  const { equities, fetchEquities } = props;
  const [copySuccess, setCopySuccess] = useState("");

  function copyToClipboard(e) {
    clipboard
      .writeText(window.location.href)
      .then(() => {
        setCopySuccess("Copied! Go share the URL!");
      })
      .catch(() => {
        setCopySuccess("Error");
      });
  }

  useEffect(() => {
    let timeout;
    if (copySuccess !== "") {
      timeout = setTimeout(() => {
        setCopySuccess("");
      }, 1400);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [copySuccess]);

  const shareStyle = {
    gridColumnStart: 2,
    gridColumnEnd: 2,
    display: "grid",
    gridTemplateColumns: "25% 50% 25%",
    gridTemplateRows: "50% 50%",
    alignItems: "center",
    justifyItems: "center",
    minWidth: "0%",
    minHeight: "0%"
  };
  const clickedStyle = {
    gridRow: "1 / -1",
    gridColumn: "1 / -1",
    display: "grid",
    backgroundColor: "#daffba",
    fontSize: "13px",
    alignItems: "center",
    justifyItems: "center",
    minWidth: "0%",
    minHeight: "0%"
  };
  const calcButtonStyle = {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    backgroundColor: "#29945c",
    color: "white",
    fontWeight: "700"
  };
  const buttonClassName =
    equities["status"] === RequestStatuses.PENDING
      ? "buttonSelected"
      : "button";

  return copySuccess ? (
    <div style={clickedStyle}>{copySuccess}</div>
  ) : (
    <>
      <button
        style={calcButtonStyle}
        className={buttonClassName}
        onClick={fetchEquities}
      >
        Calculate
      </button>
      <button style={shareStyle} className="button" onClick={copyToClipboard}>
        Share
        <div>
          <ShareIcon />
        </div>
      </button>
    </>
  );
}

export function EquityDisplay(props) {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [inputValidation, validationDispatch] = useContext(
    InputValidationContext
  );
  const [equities, setEquities] = useState({
    status: RequestStatuses.INACTIVE
  });
  const numPlayers = PLAYER_NAMES.length;
  const gridStyle = {
    gridRowStart: 1,
    gridRowEnd: 1,
    border: "solid #b9b9b9",
    display: "grid",
    gridTemplateRows: "repeat(" + (numPlayers + 1) + " , minmax(0, 1fr))",
    minWidth: "0%",
    minHeight: "0%"
  };
  const buttonRowStyle = {
    display: "grid",
    gridTemplateColumns: "64% 35%",
    gridColumnGap: ".15rem",
    gridRowStart: 1,
    gridRowEnd: 1,
    minWidth: "0%",
    minHeight: "0%"
  };
  const playerRows = PLAYER_NAMES.map((player, idx) => (
    <Row key={player} player={player} equities={equities} />
  ));
  function callFetchEquities(e) {
    return fetchEquities(e, selectedCombos, validationDispatch, setEquities);
  }
  return (
    <div style={gridStyle}>
      {playerRows}
      <div style={buttonRowStyle}>
        <CalcAndShareButtons
          equities={equities}
          fetchEquities={callFetchEquities}
        />
      </div>
    </div>
  );
}

function FoldEquityRow(props) {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const rowStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    minWidth: "0%",
    minHeight: "0%",
    alignItems: "center",
    textOverflow: "ellipsis",
    textAlign: "center"
  };
  const foldEquity = parseFoldEquity(calcFoldEquity(selectedCombos));
  return (
    <div style={rowStyle}>
      <div> Fold Equity</div>
      <div>{foldEquity}</div>
    </div>
  );
}

function EvRow(props) {
  const { equities, betSize } = props;
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );

  const rowStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    minWidth: "0%",
    minHeight: "0%",
    alignItems: "center",
    textOverflow: "ellipsis",
    textAlign: "center"
  };
  const yourEquity =
    equities.status === RequestStatuses.FULFILLED
      ? parsePlayerEquity(EV_YOUR_RANGE, equities.equities, true)
      : "_";
  const oppEquity =
    equities.status === RequestStatuses.FULFILLED
      ? parsePlayerEquity(EV_CALL_RANGE, equities.equities, true)
      : "_";
  const foldEquity = parseFoldEquity(calcFoldEquity(selectedCombos), true);
  const ev =
    equities.status === RequestStatuses.FULFILLED
      ? EvCalc(yourEquity, oppEquity, foldEquity, betSize)
      : "_";
  const evStyle = {
    color: ev === "_" ? "black" : ev > 0 ? "#216C2A" : "#d03234"
  };
  const betSizeDisplay = Math.round(betSize * 100) + "%";
  return (
    <div style={rowStyle}>
      <div> {betSizeDisplay} </div>
      <div style={evStyle}>{ev}</div>
    </div>
  );
}

function EvHeaderRow(props) {
  const rowStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    minWidth: "0%",
    minHeight: "0%",
    alignItems: "center",
    textOverflow: "ellipsis",
    textAlign: "center",
    borderTop: "solid 2px #b9b9b9"
  };
  return (
    <div style={rowStyle}>
      <div>Bet Sizing</div>
      <div>Pot % EV</div>
    </div>
  );
}

export function EvEquityDisplay(props) {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [inputValidation, validationDispatch] = useContext(
    InputValidationContext
  );
  const [equities, setState] = useState({
    status: RequestStatuses.INACTIVE
  });

  const gridStyle = {
    gridRowStart: 1,
    gridRowEnd: 1,
    border: "solid #b9b9b9",
    display: "grid",
    gridTemplateRows: "repeat(12, minmax(0, 1fr))",
    minWidth: "0%",
    minHeight: "0%"
  };
  const buttonRowStyle = {
    display: "grid",
    gridTemplateColumns: "64% 35%",
    gridColumnGap: ".15rem",
    gridRowStart: 1,
    gridRowEnd: 1,
    minWidth: "0%",
    minHeight: "0%"
  };
  const playerRows = EV_EQUITY_DISPLAY_NAMES.map((player, idx) => (
    <Row key={player} player={player} equities={equities} />
  ));
  // Add fold equity
  playerRows.push(<FoldEquityRow key={"foldEquity"} />);
  // Add EV Header Row
  playerRows.push(<EvHeaderRow key="headerRow" />);
  // Add x*PSB EV calculation
  playerRows.push(
    <EvRow key={"1.5evCalc"} equities={equities} betSize={1.5} />
  );
  playerRows.push(<EvRow key={"1evCalc"} equities={equities} betSize={1} />);
  playerRows.push(
    <EvRow key={".667evCalc"} equities={equities} betSize={0.667} />
  );
  playerRows.push(<EvRow key={".5evCalc"} equities={equities} betSize={0.5} />);
  playerRows.push(
    <EvRow key={".33evCalc"} equities={equities} betSize={0.33} />
  );
  playerRows.push(
    <EvRow key={".25evCalc"} equities={equities} betSize={0.25} />
  );
  playerRows.push(<EvRow key={".25evCalc"} equities={equities} betSize={0} />);
  function callFetchEquities(e) {
    return fetchEquities(e, selectedCombos, validationDispatch, setState);
  }
  return (
    <div style={gridStyle}>
      {playerRows}
      <div style={buttonRowStyle}>
        <CalcAndShareButtons
          equities={equities}
          fetchEquities={callFetchEquities}
        />
      </div>
    </div>
  );
}

export function DisplayRanges(props) {
  const [selectedCombos, handleSelectedDispatch] = useContext(
    HandCombosContext
  );
  const [playerSelected, handleButtonDispatch] = useContext(
    PlayerSelectedContext
  );
  const displayStyle = {
    border: "solid #b9b9b9",
    overflow: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    textAlign: "left",
    height: "48px",
    minWidth: "0%",
    minHeight: "0%"
  };
  const selected = selectedCombos[playerSelected]["selected"];
  const combos = getCombos(selectedCombos, playerSelected);
  const selectedHand = selected + " is selected: ";
  const text = combos ? selectedHand + combos : "Selected: None";
  return (
    <div className="displayer" style={displayStyle}>
      {text}
    </div>
  );
}
