import React from "react";

import { AddCombosButtons } from "./AddCombosButtons";
import { Chart, ComboChart } from "./Chart";
import { EquityDisplay, DisplayRanges } from "./RightSideBar";
import { PlayerButtons } from "./PlayerButtons";
import { BoardInput } from "./BoardInput";
import { VerticalSlider } from "./VerticalSlider";
import { LeftSideBar } from "./LeftSideBar";
import { ToolBar } from "./Toolbar";

export default function Layout(props) {
  const chartSqSize = 49;
  const chartMinSize = "860px";
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `[left-slider] 220px [left-slider-gap] 15px [left-chart-slider] 30px [left-chart-gap] 25px [chart-start] minmax(${chartMinSize}, ${chartSqSize}%) [right-chart-gap] 5% [side-bar-start] minmax(230px, 230px)[right-end-gap] minmax(5px,5px) [right-end] minmax(0%, 100%)`,
    gridTemplateRows: `[navbar-start] 76px [chart-row-start] 860px [players-row-start] 30px [footer] minmax(0%, 100%)`,
    gridRowGap: "5px",
    minHeight: "100vh",
    minWidth: "100vh"
  };
  const navBarStyle = {
    columnGap: "0px",
    gridColumnStart: "left-slider-gap",
    gridColumnEnd: "-1",
    gridRowStart: "navbar-start",
    gridRowEnd: "navbar-start"
  };
  const footerStyle = {
    display: "grid",
    gridRow: "footer"
  };
  const leftSideBarStyle = {
    columnGap: "0px",
    gridColumnStart: "left-slider",
    gridColumnEnd: "left-slider",
    gridRowStart: "navbar-start",
    gridRowEnd: "-1",
    display: "grid",
    minWidth: "0%"
  };
  const sliderStyle = {
    gridColumnStart: "left-chart-slider",
    gridColumnEnd: "left-chart-slider",
    gridRow: "chart-row-start / chart-row-start",
    display: "grid",
    minWidth: "0%",
    minHeight: "0%"
  };
  const chartAreaStyle = {
    gridColumnStart: "chart-start",
    gridColumnEnd: "chart-start",
    gridRow: "chart-row-start / chart-row-start",
    display: "grid",
    minWidth: "0%",
    minHeight: "0%"
  };
  const playerButtonStyle = {
    gridRowStart: "players-row-start",
    gridRowEnd: "players-row-start",
    gridColumnStart: "chart-start",
    gridColumnEnd: "chart-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  const sidebarWrapperStyle = {
    gridAutoRows: "max-content",
    gridColumnStart: "side-bar-start",
    girdColumnEnd: "side-bar-start",
    gridRow: "chart-row-start / -1",
    display: "grid",
    gridTemplateRows:
      "[board-start] minmax(48px, .5fr) [combo-row-start] 120px [combos-buttons-row-start] minmax(0, 1fr) [equity-start] minmax(0, 4fr) [display-start] minmax(0, 2fr)",
    minWidth: "0%",
    minHeight: "0%",
    gridRowGap: ".25rem"
  };
  const comboStyle = {
    gridRowStart: "combo-row-start",
    gridRowEnd: "combo-row-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  const boardStyle = {
    gridRowStart: "board-start",
    gridRowEnd: "board-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  const sidebarStyle = {
    gridRowStart: "equity-start",
    gridRowEnd: "equity-start",
    display: "grid",
    minWidth: "0%",
    minHeight: "0%"
  };
  const combosButtonsStyle = {
    gridRowStart: "combos-buttons-row-start",
    gridRowEnd: "combos-buttons-row-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  const displayStyle = {
    gridRowStart: "display-start",
    gridRowEnd: "display-start",
    minWidth: "0%",
    minHeight: "0%"
  };
  return (
    <div style={gridStyle}>
      <div style={navBarStyle}>
        <ToolBar />
      </div>
      <div style={leftSideBarStyle}>
        <LeftSideBar />
      </div>
      <div style={sliderStyle}>
        <VerticalSlider />
      </div>
      <div style={chartAreaStyle}>
        <Chart />
      </div>
      <div style={playerButtonStyle}>
        <PlayerButtons />
      </div>
      <div style={sidebarWrapperStyle}>
        <div style={boardStyle}>
          <BoardInput />
        </div>
        <div style={comboStyle}>
          <ComboChart />
        </div>
        <div style={combosButtonsStyle}>
          <AddCombosButtons />
        </div>
        <div style={sidebarStyle}>
          <EquityDisplay />
        </div>
        <div style={displayStyle}>
          <DisplayRanges />
        </div>
      </div>
      <div style={footerStyle} />
    </div>
  );
}
