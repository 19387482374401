import React, { useState } from "react";
import styled from "@emotion/styled";
import "rc-slider/assets/index.css";
import MetaTags from "react-meta-tags";

import { ToolBar } from "../components/Toolbar";
import Slider, { createSliderWithTooltip } from "rc-slider";
import { LeftSideBar } from "../components/LeftSideBar";

const SliderWithTooltip = createSliderWithTooltip(Slider);
const RED = "#f72430";
const GREEN = "#9bcf6a";
const WHITE = "#fff";
const NEUTRAL = "#e9e9e9";

const SliderContainer = styled.div`
  flex-basis: 51%;
  align-self: flex-start;
`;

const HeaderText = styled.div`
  flex-basis: 51%;
  color: #fff;
  margin-bottom: 0.5rem;
  align-self: flex-start;
`;

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: auto;
`;

const SliderBox = ({ rngBool, children }) => {
  return (
    <FormContainer>
      <HeaderText>Select a percentage:</HeaderText>
      <SliderContainer>{children}</SliderContainer>
    </FormContainer>
  );
};

const DisplayText = styled.span`
  color: rgba(0, 0, 0, 0.2);
  margin: auto;
  font-size: 3rem;
`;

const DisplayContainer = styled.div`
  width: 100%;
  height: minmax(640px, 100%);
  flex-basis: 40%;
  background: ${(props) => props.color};
  text-align: center;
  margin: auto;
  display: flex;
`;

const RNGDisplay = ({ percent, rngBool, loading }) => {
  const color = loading ? NEUTRAL : rngBool ? GREEN : RED;
  return (
    <DisplayContainer color={color}>
      <DisplayText>{percent}%</DisplayText>
    </DisplayContainer>
  );
};

const RandomizeButton = styled.button`
  width: 150px;
  height: 40px;
  margin: auto;
  border: 0.16em solid rgba(255,255,255,0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
  text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
  text-align: center;
  transition: all 0.2s;
  background: #2979FF;
}
`;

const RNGContainer = styled.div`
  background: #273238;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RandomNumberGenerator = () => {
  const defaultPercent = 50;
  const [percentage, updatePercentage] = useState(defaultPercent);
  const [rngBool, updateBool] = useState(true);
  const [loading, updateloading] = useState(false);
  const spinnerDisplayTimer = () => {
    setTimeout(() => {
      updateloading(false);
    }, 250);
  };
  return (
    <RNGContainer>
      <SliderBox rngBool={rngBool}>
        <SliderWithTooltip
          value={percentage}
          horizontal
          onChange={(value) => {
            updatePercentage(value);
          }}
          marks={{
            0: 0,
            25: 25,
            50: 50,
            75: 75,
            100: 100,
          }}
        />
      </SliderBox>
      <RNGDisplay
        percent={percentage}
        loading={loading}
        rngBool={rngBool}
      ></RNGDisplay>

      <RandomizeButton
        onClick={() => {
          updateloading(true);
          spinnerDisplayTimer();
          updateBool(percentage > Math.random() * 100);
        }}
      >
        Randomize
      </RandomizeButton>
    </RNGContainer>
  );
};

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DescriptionHeader = styled.h2``;
const DescriptionBody = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  font-family: Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
`;

const Description = () => {
  return (
    <DescriptionContainer>
      <DescriptionHeader>
        Get a random selection with this random number generator
      </DescriptionHeader>
      <DescriptionBody>
        To generate a random outcome, select a percentage and click "Randomize".
        For example, if you select 50%: it will display green and red half of
        the time each.. on average. If you would like to generate a 25% random
        occurence, select it with the slider. Now, 25% of the time it will
        choose green. This tool will simulate a rnd number choice between 1 and
        100.
        <br></br>
        <br></br>
        Google's RNG and others will choose a random number between 1 and 10 or
        some other input range that you decide. With this tool, you just need a
        frequency percentage and you instantly get an answer. You can use the
        tool's color choice to give you a yes or no response.
      </DescriptionBody>
      <DescriptionHeader>
        Random numbers are useful with games
      </DescriptionHeader>
      <DescriptionBody>
        In games like Texas Hold'em Poker, it is useful to randomize your
        actions against tough opponents. This makes you harder to play against.
        A good opponent will track how you play and try to counter you. When you
        randomize, your opponents counter strategy becomes less profitable. For
        example, if you never continuation bet the flop with middle pair, your
        opponent can counter you by overbetting on the turn or river. When you
        only have middle pair, and your opponent is over-betting, you're in a
        tough spot with a weak hand holding. To charge your opponent for doing
        this, use the randomizer to mix in checking with strong hand holdings -
        like top pair top kicker. You can choose a 10% frequency for something
        like that. With this tool, you can easily{" "}
        <strong>select a frequency and generate a random outcome.</strong>
      </DescriptionBody>
    </DescriptionContainer>
  );
};

const RNGMetaTags = () => {
  return (
    <div>
      <MetaTags>
        <title>Random Choice Generator With Frequency</title>
        <meta
          name="description"
          content="Create a random choice after choosing a frequency. Need to flip a coin? Choose 50%, green is heads and red is tails. Quickly make decisions with this free tool that picks randomly."
        />
        <meta property="og:title" content="HandCombos" />
      </MetaTags>
    </div>
  );
};
const RNGLayout = () => {
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `[left-slider] 220px [left-slider-gap] 3rem [rng-start] minmax(640px, 1050px) [right-rng-gap] 5% [right-end] auto`,
    gridTemplateRows: `[navbar-start] 76px [rng-row-start] minmax(400px, 100%) [description-start] auto [footer] 15px`,
    gridRowGap: "5px",
    minHeight: "100vh",
    minWidth: "100vh",
  };
  const navBarStyle = {
    columnGap: "0px",
    gridColumnStart: "left-slider-gap",
    gridColumnEnd: "-1",
    gridRowStart: "navbar-start",
    gridRowEnd: "navbar-start",
  };
  const leftSideBarStyle = {
    columnGap: "0px",
    gridColumnStart: "left-slider",
    gridColumnEnd: "left-slider",
    gridRowStart: "navbar-start",
    gridRowEnd: "-1",
    display: "grid",
    minWidth: "0%",
  };
  const rngStyle = {
    gridColumnStart: "rng-start",
    gridColumnEnd: "rng-start",
    gridRow: "rng-row-start / rng-row-start",
    display: "grid",
    minWidth: "0%",
    minHeight: "0%",
  };
  const descriptionStyle = {
    gridColumnStart: "rng-start",
    gridColumnEnd: "rng-start",
    gridRow: "description-start / description-start",
    display: "grid",
    minWidth: "0%",
    minHeight: "0%",
  };
  const footerStyle = {
    display: "grid",
    gridRow: "footer",
  };
  return (
    <>
      <RNGMetaTags></RNGMetaTags>
      <div style={gridStyle}>
        <div style={navBarStyle}>
          <ToolBar />
        </div>
        <div style={leftSideBarStyle}>
          <LeftSideBar />
        </div>
        <div style={rngStyle}>
          <RandomNumberGenerator />
        </div>
        <div style={descriptionStyle}>
          <Description></Description>
        </div>
        <div style={footerStyle}>here</div>
      </div>
    </>
  );
};

export default RNGLayout;
